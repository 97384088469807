import React, { useState } from 'react';
import { ReactComponent as SearchIcon } from "../../assets/searchGrey.svg";
import { ReactComponent as CancelIcon } from "../../assets/XGrey.svg";
import { ReactComponent as AlertIcon } from "../../assets/Alert.svg";
import { ReactComponent as FoodIcon } from "../../assets/food.svg";
import { ReactComponent as DestinationIcon } from "../../assets/DestinationIcon.svg";
import { ReactComponent as CameraIcon } from "../../assets/cameraBlack.svg";
import GlobalSearchList from './GlobalSearchList';
import SelectedTaskComponent from './SelectedTaskComponent';
import { ReactComponent as DestinationcityImage } from "../../assets/DestinationcityImage.svg";
import SelectedAlert from './SelectedAlert';

const GlobalSearchDialogueBox = ({ toggleSearch }) => {
  const [selectedTask, setSelectedTask] = useState('Destinations');

  const renderContent = () => {
    switch (selectedTask) {
      case 'Destinations':
        return (
          <>
            <SelectedTaskComponent text='Cities' imageComponent={<DestinationcityImage 
              className = 'w-[6rem]'
            />} heading='Bali' 
              className='mt-0'
            />
            <SelectedTaskComponent text='Countries' imageComponent={<DestinationcityImage 
              className = 'w-[6rem]'
            />} heading='Bali' 
              
            />
          </>
        );
      case 'Things to do':
        return (
          <SelectedTaskComponent text='Experience' imageComponent={<DestinationcityImage 
            className = 'w-[6rem]'
          />} heading='Bali' />
        );
      case 'Food':
        return (
          <SelectedTaskComponent text='Food Courts' imageComponent={<DestinationcityImage 
            className = 'w-[6rem]'
          />} heading='Bali' />
        );
      case 'Alerts':
        return (
          
          <SelectedAlert text="Regilations" placename='Bali'
          date= '10 Jul 24'
          alert='New rule alert!'
          alertDescription='The bars will be closing at 12 pm'/>
        );
      default:
        return null;
    }
  };

  return (
    <div className='w-[50.438rem] h-[30rem] rounded-[20px] cursor-default bg-white p-4 border-[1px] border-[#7D7A91]'>
      <div className="relative flex justify-start">
        <SearchIcon className="absolute left-4 top-1/2 transform -translate-y-1/2 text-[#7D7A91]" />
        <input
          type="text"
          placeholder="Search City Wiki..."
          className="w-full h-12 pl-12 rounded-3xl bg-transparent border-[1px] border-[#120e29] font-plus-dm-sans font-normal text-md text-[#220f0f] focus-within:border-[#ffb543]"
        />
        <CancelIcon
          className="absolute right-4 top-1/2 transform -translate-y-1/2 text-[#7D7A91] cursor-pointer"
          onClick={toggleSearch}
        />
      </div>

      <div className='flex cursor-pointer'>
        <div className='mt-2 flex flex-col gap-y-2'>
          <GlobalSearchList
            icon={<DestinationIcon />}
            task='Destinations'
            onClick={() => setSelectedTask('Destinations')}
            isSelected={selectedTask === 'Destinations'}
          />
          <GlobalSearchList
            icon={<CameraIcon />}
            task='Things to do'
            onClick={() => setSelectedTask('Things to do')}
            isSelected={selectedTask === 'Things to do'}
          />
          <GlobalSearchList
            icon={<FoodIcon />}
            task='Food'
            onClick={() => setSelectedTask('Food')}
            isSelected={selectedTask === 'Food'}
          />
          <GlobalSearchList
            icon={<AlertIcon />}
            task='Alerts'
            onClick={() => setSelectedTask('Alerts')}
            isSelected={selectedTask === 'Alerts'}
          />
        </div>
        <div>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default GlobalSearchDialogueBox;
