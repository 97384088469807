import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import ReactGA from 'react-ga4';
import AnalyticsTracker from './utils/analyticsTracker';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Blogs from './pages/Blogs/Blogs';
import LandingPage from './pages/Landing Page/LandingPage';
import FooterBlack from './components/Footer';
import Header from './components/Header';
import ExploreDestination from './pages/Explore Destinations/ExploreDestination';
import ItineraryPlanner from './pages/Itinerary Planner/ItineraryPlanner';
import "@buildo/bento-design-system/index.css"; // Importing the Bento Design System CSS
import "@buildo/bento-design-system/defaultTheme.css";
import { defaultMessages } from "@buildo/bento-design-system/defaultMessages/en";
import { BentoProvider } from "@buildo/bento-design-system";
import CityExplorer from './pages/CityExplorer/CityExplorer';
import Signup from './pages/Signup/Signup';
import Login from './pages/Login/Login';
import ProfileForm from './pages/ProfilePage/ProfilePage';
import LandingPage2 from './pages/Landing Page/LandingPage2';

import { requestNotificationPermission } from './utils/notifications'; // Import the function
import { onMessage } from 'firebase/messaging'; // Import onMessage from Firebase
import { messaging } from './utils/firebaseConfig'; // Import messaging instance

function App() {
  useEffect(() => {
    // Initialize Google Analytics with Measurement ID
    ReactGA.initialize('G-2R6LL12SYH');
    ReactGA.send('pageview'); // Track the initial page load

    // Request notification permission on app load
    requestNotificationPermission();

    // Set up foreground notification listener
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Message received in foreground: ", payload);
      // Optionally display notification in-app
      alert(`New notification: ${payload.notification.title}`);
    });

    // Clean up the listener when the component unmounts
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <BentoProvider defaultMessages={defaultMessages}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <>
          <Header />
          <AnalyticsTracker />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/landing" element={<LandingPage2 />} />
            <Route path="/explore-destination" element={<ExploreDestination />} />
            <Route path="/ai-travel-itinerary" element={<ItineraryPlanner />} />
            <Route path="city-explorer" element={<CityExplorer />} />
            <Route path="/blog" element={<Blogs />} />
            <Route path="/sign-up" element={<Signup />} />
            <Route path="/log-in" element={<Login />} />
            <Route path="/my-profile" element={<ProfileForm />} />
          </Routes>
          <FooterBlack />
        </>
      </ThemeProvider>
    </BentoProvider>
  );
}

export default App;
