import React from 'react'
import { ReactComponent as BeachSymbol } from "../../assets/BeachSymbol.svg";
import { ReactComponent as SportSymbol } from "../../assets/SportSymbol.svg";
import { ReactComponent as DrinkSymbol } from "../../assets/DrinkSymbol.svg";
import { ReactComponent as NightlifeSymbol } from "../../assets/NightlifeSymbol.svg";
import { ReactComponent as CloudSun } from "../../assets/CloudSun.svg";
import { ReactComponent as CloudRain } from "../../assets/CloudRain.svg";
import CityFamousActivities from './CityFamousActivities';
import BestTimeToVisit from './BestTimeToVisit';

const About = ({descriprition }) => {
  return (
    <div className='w-[33.063rem] h-[26.125rem] p-4 cursor-default font-plus-jakarta-sans font-semibold bg-[#FFF3DC] rounded-[20px]'>
      <p className='text-2xl'>About</p>
      <p className='font-dm-sans font-normal text-start'>
        {descriprition}
      </p>
    <div className='flex gap-3 mt-2'>
            <CityFamousActivities 
                symbol={<BeachSymbol />}
                activity='Beach'
            />
            <CityFamousActivities 
                symbol={<SportSymbol />}
                activity='Sports'
            />
            <CityFamousActivities 
                symbol={<DrinkSymbol />}
                activity='Drink'
            />
            <CityFamousActivities 
                symbol={<NightlifeSymbol />}
                activity='Nightlife'
            />
    </div>
    <p className='text-2xl mt-4'>Best Time to Visit</p>
    <div className='flex gap-x-7 mt-2 justify-evenly'>
        <BestTimeToVisit 
            startMonth='Nov'
            endMonth='Mar'
            tempSymbol={<CloudSun />}
            initialTemp='18'
            finalTemp='30'
            seasonType='Peak Season'
        />
        <BestTimeToVisit 
            startMonth='Jul'
            endMonth='Oct'
            tempSymbol={<CloudRain />}
            initialTemp='18'
            finalTemp='30'
            seasonType='Moderate'
        />
        <BestTimeToVisit 
            startMonth='Apr'
            endMonth='Jun'
            tempSymbol={<CloudSun />}
            initialTemp='30'
            finalTemp='38'
            seasonType='Off Season'
        />
    </div>
    </div>
    
    
    
  )
}

export default About
