import React from 'react'

const Rating = ({ratingIcon , ratingScore}) => {
  return (
    <div className='w-[71px] h-[34px] rounded-[10px] bg-[#FFEDD0] flex gap-x-1 py-1 px-2 text-[#484646] font-medium text-base'>
        <div>
            {ratingIcon}
        </div>
        <div>
            {ratingScore}
        </div>
    </div>
  )
}

export default Rating