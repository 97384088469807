import React from 'react'

const PreferencesBox = ({text , imageComponent}) => {
  return (
    <div  className=' flex gap-x-1   h-[40px] bg-white border-[1px] border-black text-base text-[#484646] hover:bg-[#FFEDD0] rounded-[10px] p-2  cursor-pointer hover:border-[0px]'>
    <div>{imageComponent}</div>
        <p>{text}</p>
    </div>
  )
}

export default PreferencesBox