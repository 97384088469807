import React from 'react'

const Alerts = ({alertHeading, alertDetail}) => {
  return (
    <div className='w-[24.5rem] h-[18.188rem] bg-[#FFFBF4] rounded-[20px] p-3'>
      <p className='font-poppins font-medium text-lg  w-[19.813rem]'>
        {alertHeading}
      </p>
      <p className='font-poppins mt-2 font-normal text-sm  w-[21.938rem]'>
        {alertDetail}
      </p>
    </div>
  )
}

export default Alerts
