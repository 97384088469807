import React from 'react'

const ListItem = ({image , text}) => {
  return (
    <div className='w-[9.438rem] h-[7rem]  bg-white'>
      <div className='w-full h-[8.625rem] '>
            {image}
      </div>
      <p className='font-plus-jakarta-sans font-semibold text-base mt-'>{text}</p>
    </div>
  )
}

export default ListItem
