import React, { useState } from 'react';
import NewTrendingDestinationCard from '../../components/landingPage/NewTrendingDestinationCard'; // Import the new card component

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const destinations = [
    { placename: "Venice" },
    { placename: "Paris" },
    { placename: "Rome" },
    { placename: "Berlin" },
    { placename: "Barcelona" },
    { placename: "Amsterdam" },
  ];

  const handleNext = () => {
    if (currentIndex < destinations.length - 3) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div>
      <p className="font-plus-jakarta-sans font-medium text-[32px] text-center mt- lg:mt-10 text-[#1C1C1C]">
        Trending Destinations
      </p>

      <div className="relative w-full flex items-center justify-center mt-6">
        {/* Left Button */}
        <button
          className={`absolute left-2 lg:left-4 p-2 bg-gray-300 rounded-full hover:bg-gray-400 ${
            currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={handlePrev}
          disabled={currentIndex === 0}
        >
          {"<"}
        </button>

        {/* Carousel Items */}
        <div className="flex overflow-hidden gap-4">
          {destinations.slice(currentIndex, currentIndex + 3).map((destination, index) => (
            <NewTrendingDestinationCard
              key={index}
              placeName={destination.placename} // Pass placeName as a prop
            />
          ))}
        </div>

        {/* Right Button */}
        <button
          className={`absolute right-2 lg:right-4 p-2 bg-gray-300 rounded-full hover:bg-gray-400 ${
            currentIndex === destinations.length - 3 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={handleNext}
          disabled={currentIndex === destinations.length - 3}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

export default Carousel;
