import React from 'react'

const FestivalsAndEvents = () => {
  return (
    <div className='w-[34.938rem] h-[9.5rem] rounded-[20px] bg-[#FFFBF4] p-5 font-plus-jakarta-sans font-semibold'>
      <p className='text-2xl'>Festivals & Events</p>

      <div className='flex gap-x-3 mt-3'>
        <div className='flex flex-col gap-y-2'>
            <p className='text-xl'>Jan</p>
            <p className='text-base'>Grape Escapade</p>
        </div>
        <div className='flex flex-col gap-y-2'>
            <p className='text-xl'>Feb</p>
            <p className='text-base'>Goa Carnival</p>
        </div>
        <div className='flex flex-col gap-y-2'>
            <p className='text-xl'>3rd Dec</p>
            <p className='text-base'>Feast of St. Xavier</p>
        </div>
      </div>
    </div>
  )
}

export default FestivalsAndEvents
