import React from 'react'
import Venicee  from "../../assets/venice2.svg";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CardPreferenceTag from './CardPreferenceTag';
import KayakingIcon from '@mui/icons-material/Kayaking';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';


const NewPopularItineraryCard = ({placeName , days , nights , rate}) => {
  return (
    <div
      className={`w-full lg:w-[27.513rem] p-2 lg:p-4 lg:h-[32.625rem] rounded-[1.25rem] shadow-xl font-plus-jakarta-sans font-medium `}
      style={{
        
        backgroundImage: `url('${Venicee}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
        <div className='w-[97px] h-[38px] rounded-[10px] text-[#484646] text-[22px] px-2 bg-white font-semibold'>
            {placeName}
        </div>
        <div className='w-[409px] h-[160px] py-3 px-5 font-medium bg-white rounded-[10px] mt-[70%]'>
            <div className='flex justify-between'>
                <div className='flex gap-x-1'>
                <AccessTimeIcon />
                <p>Duration: {days} days, {nights} nights</p>
                </div>
                <div>
                    <p>₹{rate}/Day</p>
                </div>

            </div>

            <div className='mt-3 flex gap-x-2'>
                <CardPreferenceTag 
                    imageComponent={               <FavoriteBorderIcon />}
                    text='Couple Trip'
                />
                <CardPreferenceTag 
                    imageComponent={               <KayakingIcon />}
                    text='Boating'
                />
                <CardPreferenceTag 
                    imageComponent={               <RestaurantIcon />}
                    text='Food'
                />
            </div>
            <button className='w-full h-11 lg:mt-3 px-2 rounded-lg  text-[#1C1C1C] text-center bg-[#FFD27A] font-poppins font-medium hover:bg-[#1C1C1C] hover:text-[#ffff]'>
            Plan your trip
        </button>
        </div>
    </div>
  )
}

export default NewPopularItineraryCard