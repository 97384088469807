import React from 'react'
import ListItem from './ListItem'

const SelectedTaskComponent = ({text , imageComponent , heading}) => {
return (
    <div className='bg-white font-plus-jakarta-sans font-[#8F8F8F] text-base p-3'>
        <p className='mt-4'>{text}</p>
        <div className='flex flex-wrap gap-x-5 mt-'>
            <ListItem 
            image={imageComponent }
            text = {heading}
            />
            <ListItem 
            image={imageComponent}
            text = {heading}
            />
            <ListItem 
            image={imageComponent}
            text = {heading}
            />
        </div>
        
    
    </div>
)
}

export default SelectedTaskComponent
