import React from 'react';

const Box2 = ({
  imageComponent,
  text,
  backgroundColor = "#ffffff",
  description,
  isSelected,
  onClick,
}) => {
  return (
    <div
      className={`rounded-lg transition duration-300 ease-in-out border-[1.4px] border-[#080c144d] overflow-hidden w-[18.313rem] lg:w-[20rem] h-20  lg:mt-8 flex cursor-pointer ${
        isSelected ? 'bg-[#FFD27A]' : 'bg-white hover:bg-[#FFD27A]'
      }`}
      onClick={onClick}
    >
      <div className="mt-0 p-4">{imageComponent}</div>
      <div>
        <div className="font-semibold text-sm lg:text-xl font-plus-jakarta-sans ml-4 mt-2">
          {text}
        </div>
        <div className="font-light font-plus-jakarta-sans ml-0 text-[#494A52] mt-2">
          {description}
        </div>
      </div>
    </div>
  );
};

export default Box2;
