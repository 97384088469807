import React from 'react'
import Venicee  from "../../assets/venice2.svg";
import Rating from './Rating';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import CardPreferenceTag from './CardPreferenceTag';
import KayakingIcon from '@mui/icons-material/Kayaking';


const NewOtherLocationCard = ({placeName}) => {
  return (
    <div
      className={`w-full lg:w-[27.513rem] p-2 lg:p-4 lg:h-[32.625rem] rounded-[1.25rem] shadow-xl font-plus-jakarta-sans font-medium `}
      style={{
        
        backgroundImage: `url('${Venicee}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
        <div className='w-[97px] h-[38px] rounded-[10px] text-[#484646] text-[22px] px-2 bg-white font-semibold'>
            {placeName}
        </div>
        <div className='w-[409px] h-[160px] py-3 px-5 font-medium bg-white rounded-[10px] mt-[70%]'>
            <div className='flex gap-x-2'>
                <Rating ratingIcon={<RestaurantIcon />}
                    ratingScore='4.2'
                />
                <Rating ratingIcon={<RestaurantIcon />}
                    ratingScore='4.2'
                />
                <Rating ratingIcon={<SportsBarIcon />}
                    ratingScore='3.2'
                />
                <Rating ratingIcon={<SportsBarIcon />}
                    ratingScore='3.2'
                />
            </div>

            <div className='mt-3 flex gap-x-2'>
                <CardPreferenceTag 
                    imageComponent={               <KayakingIcon />}
                    text='Water'
                />
                <CardPreferenceTag 
                    imageComponent={               <KayakingIcon />}
                    text='Heritage'
                />
                <CardPreferenceTag 
                    imageComponent={               <KayakingIcon />}
                    text='Architecture'
                />
            </div>
            <button className='w-full h-11 lg:mt-3 px-2 rounded-lg  text-[#1C1C1C] text-center bg-[#FFD27A] font-poppins font-medium hover:bg-[#1C1C1C] hover:text-[#ffff]'>
            Plan your trip
        </button>
        </div>
    </div>
  )
}

export default NewOtherLocationCard