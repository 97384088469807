import React, { useState } from 'react';
import { ReactComponent as Beach } from "../../assets/beachBlack.svg";
import { ReactComponent as Venice } from "../../assets/venice.svg";
import { ReactComponent as Paris } from "../../assets/paris.svg";
import { ReactComponent as Romee } from "../../assets/rome2.svg";
import { ReactComponent as Venicee } from "../../assets/venice2.svg";
import NewOtherLocationCard from './NewOtherLocationCard';

const CarouselOtherLocation = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const destinations = [
    {
      imageComponent: <Venicee className='w-full lg:w-[25.375rem] h-[15rem] lg:h-[16rem]' />,
      placeName: "Venice",
      travelTime: "13hrs 40mins",
      tags: ["Water", "Heritage", "Architecture"],
    },
    {
      imageComponent: <Paris className='w-full lg:w-[25.375rem] h-[15rem] lg:h-[16rem]' />,
      placeName: "Paris",
      travelTime: "13hrs 40mins",
      tags: ["Beach", "Romantic", "Cuisine"],
    },
    {
      imageComponent: <Romee className='w-full lg:w-[25.375rem] h-[15rem] lg:h-[16rem]' />,
      placeName: "Rome",
      travelTime: "13hrs 40mins",
      tags: ["History", "Culture", "Architecture"],
    },
    {
      imageComponent: <Paris className='w-full lg:w-[25.375rem] h-[15rem] lg:h-[16rem]' />,
      placeName: "Berlin",
      travelTime: "10hrs 20mins",
      tags: ["City", "Nightlife", "Art"],
    },
    {
      imageComponent: <Paris className='w-full lg:w-[25.375rem] h-[15rem] lg:h-[16rem]' />,
      placeName: "Paris",
      travelTime: "13hrs 40mins",
      tags: ["Beach", "Romantic", "Cuisine"],
    },
    {
      imageComponent: <Venicee className='w-full lg:w-[25.375rem] h-[15rem] lg:h-[16rem]' />,
      placeName: "Venice",
      travelTime: "13hrs 40mins",
      tags: ["Water", "Heritage", "Architecture"],
    },
  ];

  const handleNext = () => {
    if (currentIndex < destinations.length - 3) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="relative w-full flex items-center justify-center mt-6">
      {/* Left Button */}
      <button
        className={`absolute left-2 lg:left-4 p-2 bg-gray-300 rounded-full hover:bg-gray-400 ${
          currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        onClick={handlePrev}
        disabled={currentIndex === 0}
      >
        {"<"}
      </button>

      {/* Carousel Items */}
      <div className="w-full overflow-hidden">
        <div
          className="flex transition-transform duration-500"
          style={{ transform: `translateX(-${currentIndex * 33.33}%)` }}
        >
          {destinations.map((destination, index) => (
            <div
              key={index}
              className="w-1/3 flex-shrink-0 flex justify-center items-center "
            >
              <NewOtherLocationCard
                placeName={destination.placeName}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Right Button */}
      <button
        className={`absolute right-2 lg:right-4 p-2 bg-gray-300 rounded-full hover:bg-gray-400 ${
          currentIndex >= destinations.length - 3 ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        onClick={handleNext}
        disabled={currentIndex >= destinations.length - 3}
      >
        {">"}
      </button>
    </div>
  );
};

export default CarouselOtherLocation;
