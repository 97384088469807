import React from 'react'

const PlacenameTag = ({placename}) => {
  return (
    <div className='bg-[#F3EAD7] w-[7.25rem] py-1 rounded-[20px]'>
      <p className='font-dm-sans font-normal text-sm text-center'>{placename}</p>
    </div>
  )
}

export default PlacenameTag
