import React, { useState } from 'react';
import { ReactComponent as ExploreDestinationMap } from "../../assets/exploreDestinationMap.svg";
import MapDetailView from "../../assets/MapDetailView.svg";
import { ReactComponent as FilterIcon } from "../../assets/filterIcon.svg";
import { ReactComponent as LocationIcon } from "../../assets/locationIcon.svg";
import { ReactComponent as CardViewIcon } from "../../assets/cardView.svg";
import { ReactComponent as TableViewICon } from "../../assets/tableView.svg";
import { ReactComponent as ExplorePlacesCompass } from "../../assets/explorePlacesCompass.svg";

import { ReactComponent as Budget } from "../../assets/Thermometer.svg";
import { ReactComponent as Flight } from "../../assets/planeBlack.svg";
import { ReactComponent as Temperature } from "../../assets/Thermometer.svg";
import { ReactComponent as Regulation } from "../../assets/regulation.svg";

import { ReactComponent as Venicee } from "../../assets/venice2.svg";
import { ReactComponent as Bangkok } from "../../assets/bangkok.svg";
import { ReactComponent as Beach } from "../../assets/beachBlack.svg";
import ExploreDestinationListView from '../../components/ExploreDestinations/ExploreDestinationListView';
import ExploreDestinationCardView from '../../components/ExploreDestinations/ExploreDestinationCardView';
import FilterAndActivitiesOptions from '../../components/ExploreDestinations/FilterAndActivitiesOptions';
import FilterAndActivites from '../../components/ExploreDestinations/FilterAndActivities';

const ExploreDestination = () => {
  const [showListView, setShowListView] = useState(true);
  const [showFilter , setShowFilter] = useState(false);

  const handleCardClick = () => {
    setShowListView(prevState => !prevState);
  };
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  }
  const handleCloseFilter = () => {
    setShowFilter(false);
  };


  const filterItems = [
  <FilterAndActivitiesOptions icon=<LocationIcon />
    optionType ='Location'
  /> ,
  <FilterAndActivitiesOptions icon=<Budget />
  optionType ='Budget'
/> ,
<FilterAndActivitiesOptions icon=<Flight />
    optionType ='Travel Time'
  /> ,
  <FilterAndActivitiesOptions icon=<Temperature />
  optionType ='Temperature'
/>  ,
<FilterAndActivitiesOptions icon=<Regulation />
    optionType ='Regulations'
  /> 

   ]; 

  return (
    <div className='lg:flex h-full lg:h-[61.625rem]'>
      <div className='h-full w-full lg:w-[50%]'>
        <div className='flex flex-col items-center justify-center px-5 text-center'>
          <p className='font-plus-jakarta-sans font-semibold text-[32px]  mt-12'>
            Destinations that will suit you!
          </p>
          <p className='font-dm-sans font-normal text-lg  mt-1'>
            We have found places for you to explore!
          </p>
        </div>
        <div
          className=" lg:hidden flex flex-col items-center justify-center m-2" 
          style={{
            backgroundImage: `url('${MapDetailView}')`,
            backgroundSize: "auto",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: '9.708rem',
          }}
        >
          <div className='flex w-[18.125rem] bg-white h-12 rounded-[12px] shadow-xl p-2 hover:bg-[#FFC34F] mt-9  font-plus-jakarta-sans font-semibold gap-x-6 justify-center'>
            <LocationIcon />
            <p>Explore on maps &gt;</p>
          </div>
        </div>
        <div className='flex mt-4 justify-center gap-x-[2rem] lg:gap-x-[20rem]'>
          
          <div className='flex bg-[#F3EAD7] w-[8rem] h-[2.667rem] font-dm-sans font-medium hover:bg-[#FFC34F] rounded-3xl gap-x-3 p-3' onClick={handleFilterClick}>
              <FilterIcon />
              <p>Filters &gt;</p>
          </div>
          {showFilter && (
            <div className="fixed inset-0 z-50 flex justify-start items-center bg-black bg-opacity-50">
        <FilterAndActivites title="Filter" items={filterItems} onClose={handleCloseFilter}/>
        </div>
      )}
      
      
          <div
            className=' flex bg-[#F3EAD7] w-[8.127rem] h-[2.667rem] lg:mr-7 rounded-3xl px-2 hover:bg-[#FFC34F] cursor-pointer'
            onClick={handleCardClick}
          >
            <TableViewICon className='absolute  mt-2' />
            <div className='bg-[#FFC34F] w-[5.5rem] h-8 rounded-3xl px-2 ml-7 text-right font-dm-sans font-medium hover:bg-[#F3EAD7] mt-1'>
              <CardViewIcon className='absolute mt-1' /> { showListView ? (<p className='px-1 text-lg'> Card </p>) : (<p className='px-1 text-lg'> List </p>) }

            </div>
          </div>
        </div>
        {showListView ? (
          <div className='overflow-auto hover:overflow-y-scroll h-[30.5rem] mt-4  lg:block hidden'>
            <ExploreDestinationListView 
              placeImage={<Bangkok />}
              placename='Bangkok'
              peakSeason='Peak season during Jan to Mar'
              peopleVisit='10,00,000'
            />
            <ExploreDestinationListView 
              placeImage={<Bangkok />}
              placename='Bangkok'
              peakSeason='Peak season during Jan to Mar'
              peopleVisit='10,00,000'
            />
            <ExploreDestinationListView 
              placeImage={<Bangkok />}
              placename='Bangkok'
              peakSeason='Peak season during Jan to Mar'
              peopleVisit='10,00,000'
            />
            <ExploreDestinationListView 
              placeImage={<Bangkok />}
              placename='Bangkok'
              peakSeason='Peak season during Jan to Mar'
              peopleVisit='10,00,000'
            />
            <ExploreDestinationListView 
              placeImage={<Bangkok />}
              placename='Bangkok'
              peakSeason='Peak season during Jan to Mar'
              peopleVisit='10,00,000'
            />
            <ExploreDestinationListView 
              placeImage={<Bangkok />}
              placename='Bangkok'
              peakSeason='Peak season during Jan to Mar'
              peopleVisit='10,00,000'
            />
            <ExploreDestinationListView 
              placeImage={<Bangkok />}
              placename='Bangkok'
              peakSeason='Peak season during Jan to Mar'
              peopleVisit='10,00,000'
            />
            <ExploreDestinationListView 
              placeImage={<Bangkok />}
              placename='Bangkok'
              peakSeason='Peak season during Jan to Mar'
              peopleVisit='10,00,000'
            />
          </div>
        ) : (
          <div className='overflow-y hover:overflow-y-scroll h-full lg:h-[40.5rem] mt-7'>
            <div className='flex flex-col lg:flex-row justify-center items-center gap-9'>
              <ExploreDestinationCardView 
                imageComponent={<Venicee className='w-full h-full'/>}
                placename="Venice"
                travelTime="13hrs 40mins"
                tag={<Beach className=" w-6"/>}
                placeType="Water"
                travelMonth="Jan to July & Sep to Dec"
              />
              <ExploreDestinationCardView 
                imageComponent={<Venicee className='w-full h-full'/>}
                placename="Venice"
                travelTime="13hrs 40mins"
                tag={<Beach className=" w-6"/>}
                placeType="Water"
                travelMonth="Jan to July & Sep to Dec"
              />
              
            </div>
            <div className='flex flex-col lg:flex-row items-center justify-center mt-4 gap-9'>
              <ExploreDestinationCardView 
                imageComponent={<Venicee className='w-full h-full'/>}
                placename="Venice"
                travelTime="13hrs 40mins"
                tag={<Beach className=" w-6"/>}
                placeType="Water"
                travelMonth="Jan to July & Sep to Dec"
              />
              <ExploreDestinationCardView 
                imageComponent={<Venicee className='w-full h-full'/>}
                placename="Venice"
                travelTime="13hrs 40mins"
                tag={<Beach className=" w-6"/>}
                placeType="Water"
                travelMonth="Jan to July & Sep to Dec"
                
              />
              
            </div>
          </div>
        )}
      </div>
      <div className='h-full ml-12'>
        <ExploreDestinationMap className='hidden lg:block lg:w-full' />
        <div className='hidden lg:flex w-[14.75rem] h-[4.5rem] bg-[#FFD27A]  font-dm-sans font-medium p-2 gap-2 rounded-lg absolute top-72 left-[80%]'>
          <ExplorePlacesCompass />
          <div>
            <p className='font-dm-sans font-medium text-[20px]'>
              Keep Exploring
            </p>
            <p className='font-dm-sans font-medium text-sm'>
              Find destinations
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExploreDestination;
