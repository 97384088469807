import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as TravelMaglQLogo } from "../assets/TravelMagIQ_White_LOGO.svg";
import { ReactComponent as MailIcon } from "../assets/mailIcon.svg";
import footerImage from "../assets/footerImage.svg";

const FooterBlack = () => {
  return (
    <>
    <div >
      <footer
        className="absolute w-full bg-[] lg:h-[765px] h-[40.75rem]"
        style={{
          backgroundImage: `url(${footerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
      <div
            className="absolute inset-0 bg-black font-plus-jakarta-sans text-white text-center flex flex-col items-center justify-center"
            style={{ opacity: 0.2 }}
        /> 
        <div className="relative">
        <div className="flex justify-center">
            <p className="font-plus-jakarta-sans text-[#ffff] text-center font-semibold text-[20px] lg:text-[32px] relative mt-24">
              Plan your travel journey with ease!
            </p>
          </div>
          <div className="flex justify-center">
          <button className="block bg-[#ffffff] text-[#1C1C1C] font-dm-sans font-semibold py-2 px-4 rounded-xl w-[11.3rem] h-[2.5rem] hover:bg-[#FFF3DC] hover:text-[#1C1C1C] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mt-4 ml-3">
              Get Started Now
            </button>
            </div>
          <div className="flex mt-20 lg:mt-48 justify-center">

            <TravelMaglQLogo className="h-12 lg:h-[119.78px] w-[339px]" />
          </div>
          
          <div className="flex mt-9 justify-center">
            <div className="relative flex justify-start text-[#fff]">
              <MailIcon className="absolute left-4 top-1/2 transform -translate-y-1 text-[#fff]" />
              <button className="hidden lg:block bg-[#ffffff] text-[#1C1C1C] font-dm-sans font-semibold py-2 px-4 rounded-xl w-[7.313rem] h-[2.5rem] hover:bg-[#FFF3DC] hover:text-[#1C1C1C] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mt-4 ml-3 absolute right-[0.5rem] translate-y-1">
              Try Now
            </button>
              <input
                type="text"
                placeholder=""
                className="w-[150px] lg:w-[633px] h-[3.25rem] rounded-xl bg-transparent border-[1px] border-[#ffffff] mt-4 px-16 font-plus-dm-sans font-normal text-md text-[#fff]"
              />
              
            </div>
            
          </div>
          <nav className="flex flex-col lg:flex-row lg:gap-20 lg:mt-12 p-4 items-center justify-center ">
            <Link
              to="/features"
              className="text-[#fff] font-poppins font-normal no-underline text-md hover:text-[#FFC34F] flex items-center h-8 py-2"
            >
              Features
            </Link>
            <Link
              to="/plansandpricing"
              className="text-[#fff] font-poppins font-normal no-underline text-md hover:text-[#FFC34F] flex items-center h-8 py-2"
            >
              Plans & Pricing
            </Link>
            <Link
              to="/newsandblogs"
              className="text-[#fff] font-poppins font-normal no-underline text-md hover:text-[#FFC34F] flex items-center h-8 py-2"
            >
              News & Blogs
            </Link>
            <Link
              to="/careers"
              className="text-[#fff] font-poppins font-normal no-underline text-md hover:text-[#FFC34F] flex items-center h-8 py-2"
            >
              Careers
            </Link>
            <Link
              to="/aboutus"
              className="text-[#fff] font-poppins font-normal no-underline text-md hover:text-[#FFC34F] flex items-center h-8 py-2"
            >
              About Us
            </Link>
            <Link
              to="/Terms"
              className="text-[#fff] font-poppins font-normal no-underline text-md hover:text-[#FFC34F] flex items-center h-8 py-2"
            >
              Terms
            </Link>
            <Link
              to="/privacy"
              className="text-[#fff] font-poppins font-normal no-underline text-md hover:text-[#FFC34F] flex items-center h-8 py-2"
            >
              Privacy
            </Link>
            <Link
              to="/cookie"
              className="text-[#fff] font-poppins font-normal no-underline text-md hover:text-[#FFC34F] flex items-center h-8 py-2"
            >
              Cookies
            </Link>
          </nav>
        </div>
    
      </footer>
      </div>
    </>
  );
};

export default FooterBlack;
