import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FamilyRestroomOutlinedIcon from "@mui/icons-material/FamilyRestroomOutlined";
import EventIcon from "@mui/icons-material/Event";
import Checkbox from "@mui/material/Checkbox";
import DatePicker from "react-datepicker";
import PreferencesBox from "./PreferencesBox";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Input } from "@mui/material";

const NewPlanMyItinerary = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [budget, setBudget] = useState([1, 10]);

  const handleBudgetChange = (event, newValue) => {
    setBudget(newValue);
  };

  const valuetext = (value) => `{value}K`;
  return (
    <div className="w-[398px] lg:w-[1193px] lg:h-[416px] bg-white p-2 lg:p-4 font-Plus Jakarta Sans font-medium">
      <div className="flex gap-x-4">
        <ArrowBackIcon className="text-[#3D4F67]" />
        <p className="text-[#797676]">Step 1 of 2</p>
      </div>
      <div className="px-10 mt-2">
        <div className="flex gap-x-24">
          <p className="text-[#3D4F67] font-semibold text-2xl">
            Where would you like to go?
          </p>
          <div className=" hidden lg:flex gap-x-2 ml-[6.5rem] cursor-pointer">
            <PreferencesBox
              imageComponent={<Person2OutlinedIcon />}
              text="Solo Trip"
            />
            <PreferencesBox
              imageComponent={<GroupsOutlinedIcon />}
              text="Group Trip"
            />
            <PreferencesBox
              imageComponent={<FavoriteBorderOutlinedIcon />}
              text="Couple Trip"
            />
            <PreferencesBox
              imageComponent={<FamilyRestroomOutlinedIcon />}
              text="Family Trip"
            />
          </div>
        </div>
      
      <div className="flex flex-col lg:flex-row text-base">
        <div className="lg:w-[50%] ">
          <p className="text-[#484646] mt-2 font-medium">Destination</p>
          <input
            className="border-[1px] border-black rounded-[10px] w-[300px] lg:w-[391px] h-[56px] flex p-3 cursor-pointer"
            placeholder="Destination"
          />
          <div className="flex">
            <Checkbox defaultChecked color="default" />
            <p className="mt-2">Travelling from multiple cities?</p>
          </div>
        </div>
        <div className="lg:w-[50%]">
          <p className="text-[#484646] mt-2 font-medium">Duration</p>
          <div className="flex">
            <div className="w-[240px] h-[56px] border-[1px] border-black rounded-[10px] p-3  flex cursor-pointer">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="dd/MM/yyyy"
                placeholderText="Start Date"
                showYearDropdown
                showMonthDropdown
                popperPlacement="right"
                className="text-dm-sans font-normal text-[#131a23] w-full"
              />
              <EventIcon />
            </div>
            <div className="w-[240px] h-[56px] border-[1px] border-black rounded-[10px] p-3 ml-3 flex cursor-pointer">
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="dd/MM/yyyy"
                placeholderText="End Date"
                showYearDropdown
                showMonthDropdown
                minDate={startDate}
                popperPlacement="right"
                className="text-dm-sans font-normal text-[#131a23] w-full"
              />
              <EventIcon />
            </div>
          </div>
          <div className="flex">
            <Checkbox defaultChecked color="default" />
            <p className="mt-2">Not sure? Let us help</p>
          </div>
        </div>
      </div>
      <div>
        <p className="text-[#484646] mt-2 font-medium">Budget</p>
      </div>
      <div className="mt-4 flex-col lg:flex-row flex gap-x-4">
        <div className="mt-3">
          <div className="flex   gap-x-2 ">
            <p>1k</p>

            <Box sx={{ width: 370 }}>
              <Slider
                getAriaLabel={() => "Budget range"}
                value={budget}
                onChange={handleBudgetChange}
                valueLabelDisplay="on"
                getAriaValueText={valuetext}
              />
            </Box>

            <p>10K</p>
          </div>
        </div>
        <div className="flex gap-x-2">
        <input
          className="border-[1px] border-black rounded-[10px] text-black text-base w-[150px] lg:w-[218px] h-[56px] lg:ml-[3.5rem] p-2"
          placeholder="0$"
        />
        <input
          className="border-[1px] border-black rounded-[10px] text-black text-base w-[150px] lg:w-[218px] h-[56px] p-2"
          placeholder="500$"
        />
        </div>
      </div>
      <p className=" lg:hidden text-[#484646] mt-2 font-medium">Trip Type</p>
      <div className="grid grid-cols-2 lg:hidden gap-2  cursor-pointer mt-2">
            <PreferencesBox
              imageComponent={<Person2OutlinedIcon />}
              text="Solo Trip"
            />
            <PreferencesBox
              imageComponent={<GroupsOutlinedIcon />}
              text="Group Trip"
            />
            <PreferencesBox
              imageComponent={<FavoriteBorderOutlinedIcon />}
              text="Couple Trip"
            />
            <PreferencesBox
              imageComponent={<FamilyRestroomOutlinedIcon />}
              text="Family Trip"
            />
          </div>
      <div className="flex mt-2 gap-x-2 lg:ml-[850px] cursor-pointer">
        <div className="border-[1px] border-[#FFB543] rounded-[10px] text-center w-[179px] lg:w-[113px] h-[40px]">
          <p className="mt-2">Back</p>
        </div>
        <div className="h-[40px] w-[179px] lg:w-[110px] bg-[#FFB543] text-black text-center rounded-[10px]">
          <p className="mt-2"> Continue</p>
        </div>
      </div>
    </div>
    
    </div>
  );
};

export default NewPlanMyItinerary;
