import React from 'react'

const StayButton = ({buttonText}) => {
return (
    <div>
        <button
        className='w-full rounded-[10px] p-3 bg-[#1C1C1C] hover:bg-[#FFB543] hover:text-[#1C1C1C] text-white font-poppins font-semibold'
        >
            {buttonText}
        </button>
    </div>
)
}

export default StayButton
