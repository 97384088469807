import React, { useState } from "react";
import NewPopularItineraryCard from "../../components/landingPage/NewPopularItineraryCard";

const CarouselPopularItenarires = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const destinations = [
    {
      placeName: "Venice",
      days: 5,
      nights: 4,
      rate: 15000,
    },
    {
      placeName: "Paris",
      days: 7,
      nights: 6,
      rate: 20000,
    },
    {
      placeName: "Rome",
      days: 4,
      nights: 3,
      rate: 18000,
    },
    {
      placeName: "Berlin",
      days: 6,
      nights: 5,
      rate: 17000,
    },
    {
      placeName: "Barcelona",
      days: 8,
      nights: 7,
      rate: 22000,
    },
    {
      placeName: "Amsterdam",
      days: 5,
      nights: 4,
      rate: 16000,
    },
  ];

  const handleNext = () => {
    if (currentIndex < destinations.length - 3) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div>
      <p className="font-plus-jakarta-sans font-medium text-[32px] text-center mt- lg:mt-10 text-[#1C1C1C]">
        Popular Itineraries
      </p>

      <div className="relative w-full flex items-center justify-center mt-6">
        {/* Left Button */}
        <button
          className={`absolute left-2 lg:left-4 p-2 bg-gray-300 rounded-full hover:bg-gray-400 ${
            currentIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={handlePrev}
          disabled={currentIndex === 0}
        >
          {"<"}
        </button>

        {/* Carousel Items */}
            {/* <div className="w-full lg:w-4/5 overflow-hidden">
              <div
                className="flex transition-transform duration-500"
                style={{
                  transform: `translateX(-${currentIndex * 33.33}%)`,
                }}
              >
                {destinations.map((destination, index) => (
                  <div
                    key={index}
                    className="flex-shrink-0 w-[calc(100%/3)] px-4"
                  >
                    <NewPopularItineraryCard
                      placeName={destination.placeName}
                      days={destination.days}
                      nights={destination.nights}
                      rate={destination.rate}
                    />
                  </div>
                ))}
              </div>
            </div> */}
            <div className="flex overflow-hidden gap-4 transition-transform duration-500">
          {destinations.slice(currentIndex, currentIndex + 3).map((destination, index) => (
            <NewPopularItineraryCard
                      placeName={destination.placeName}
                      days={destination.days}
                      nights={destination.nights}
                      rate={destination.rate}
                    />
          ))}
        </div>

        {/* Right Button */}
       
        <button
          className={`absolute right-2 lg:right-4 p-2 bg-gray-300 rounded-full hover:bg-gray-400 ${
            currentIndex >= destinations.length - 3
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
          onClick={handleNext}
          disabled={currentIndex >= destinations.length - 3}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

export default CarouselPopularItenarires;
