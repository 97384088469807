import React from 'react'
import { ReactComponent as Bed} from "../../assets/Bed.svg";
import { ReactComponent as Modify } from "../../assets/modify.svg";
import StayTags from './StayTags';

const StayDetailBox = ({checkingDate ,checkoutDate,stayPlaceName, stayDuration , checkingTime, checkoutTime }) => {
  return (
    <div className='w-[76.563rem] h-[14.688rem] rounded-[20px] shadow-xl shadow-[#0a32ab1a] p-4 cursor-default'>
        <div className='flex justify-between p-4'>
                <div className='font-poppins font-semibold text-xl'>
                    <p>{checkingDate} to {checkoutDate}</p>
                </div>
                <div className='flex gap-2 cursor-pointer'>
                    <Modify className='w-6 h-6'/> 
                    <p className='text-[#8F8F8F] text-base font-poppins font-medium'>Modify Booking</p>
                </div>
        </div>
        <div className='flex gap-7 ml-4'>
                <Bed className='w-8 h-8'/>
                <p className='font-poppins font-medium text-xl'>{stayPlaceName}</p>
                <p className='text-[#848484] text-base font-poppins font-medium'>{stayDuration}</p>
        </div>

        <div className='flex gap-7 text-base font-poppins font-medium p-4 px-[4.6rem]'>
            <p> Check in at {checkingTime}</p>
            <p> Check out at {checkoutTime}</p>
        </div>
        <div className='flex p-4 gap-4 px-[4.3rem]'>
            <StayTags tag='Breakfast included'/>
            <StayTags tag='Breakfast included'/>
        </div>
    </div>
  )
}

export default StayDetailBox
