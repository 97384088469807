import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import { ReactComponent as Clock } from "../../assets/clock.svg";

const AddPlanDialogBox = () => {

  return (
    <div className='absolute top-[55%] left-[35%]'>
      <div className='w-[32rem] h-auto bg-white rounded-[20px] p-6 cursor-default shadow-2xl'>
        <p className='font-plus-jakarta-sans font-semibold text-[36px] py-3 text-center'>Add Your Plan</p>
        <p className='font-dm-sans font-medium text-base mb-3'>Add An Activity</p>
        <div className='flex mb-4'>
          <input 
            type='text'
            placeholder='Type activity'
            className='w-[16.063rem] h-10 text-[#121c2a] border-[1px] border-[#121e2e4d] rounded-[10px] p-2'
          />
        </div>
      
        <p className='font-dm-sans font-medium text-base mb-3'>Estimated Duration</p>
        <div className='flex mb-4'>
          <input 
            type='text'
            placeholder='Enter duration in minutes'
            className='w-[16.063rem] h-10 text-[#121c2a] border-[1px] border-[#121e2e4d] rounded-[10px] p-2'
          />
          <Clock className='w-6 h-6 mt-2 ml-3' />
        </div>
        
        <div className='flex gap-2 ml-[60%]'>
            <button
            className='w-[6.938rem] h-[2.75rem] text-center bg-[#D9D9D9] rounded-[100px] font-dm-sans font-medium text-base'
            >
            Not now
            </button>
            <button
            className='w-[6.938rem]  h-[2.75rem] text-center bg-[#FFC34F] rounded-[100px] font-dm-sans font-medium text-base'
            >
            Add
            </button>
        </div>
      </div>
    </div>
  );
}

export default AddPlanDialogBox;
