import React from 'react'
import PackingGuideImageBox from './PackingGuideImageBox'

const PackingGuides = ({ heading, img1, img2, img3, name1, name2, name3, line1, line2, line3, line4, line5, line6 }) => {
  return (
    <div className='w-[24.5rem] h-[28.813rem] bg-[#FFFBF4] rounded-[20px]'>
        <div>
            <p className='font-poppins font-medium text-center text-2xl mt-2'>
                {heading}
            </p>
        </div>
        <div className='flex justify-center gap-4 p-4'>
            <PackingGuideImageBox 
              imageComponent={img1}
              text = {name1}
            />
            <PackingGuideImageBox 
              imageComponent={img2}
              text = {name2}
            />
            <PackingGuideImageBox 
              imageComponent={img3}
              text = {name3}
            />
        </div>
        <ol className='mt-3 font-poppins font-normal text-sm list-decimal list-outside ml-9 w-[20.75rem] '>
          <li>{line1}</li>
          <li>{line2}</li>
          <li>{line3}</li>
          <li>{line4}</li>
          <li>{line5}</li>
          <li>{line6}</li>
        </ol>
    </div>
  )
}

export default PackingGuides
