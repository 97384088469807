import React from 'react'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Bangkok } from "../../assets/bangkok.svg";
import { ReactComponent as Star } from "../../assets/singleStar.svg";
import { ReactComponent as Nightclub } from "../../assets/nightClub.svg";
import { ReactComponent as Spiritual } from "../../assets/spiritual.svg";
import { ReactComponent as Hill } from "../../assets/hill.svg";
import { ReactComponent as BeachLanding } from "../../assets/beachLanding.svg";
import Tags from './Tags';


const ExploreDestinationListView = ({placeImage,placename, peakSeason, peopleVisit}) => {
    const navigate = useNavigate();
    const handleCardClick = () => {
        navigate('/city-explorer')
    }
return (
    <div className='relative w-[36.875rem] h-[8.875rem] border-[1px] border-[rgb(217,217,217)] rounded-2xl  p-2 mt-6 flex ml-32' onClick={handleCardClick}>

        {placeImage}
        <div className='ml-4'>
            <p className='font-poppins font-medium text-xl'>
                {placename}
            </p>
            <p className='font-poppins font-normal text-[14px] text-[#7D7A91]'>
                {peakSeason}
            </p>
            <p className='font-poppins font-semibold text-xs mt-4'>
              Avg {peopleVisit} people visit
            </p>
            <div className='flex gap-1 mt-1 '>
                <Tags 
                    ImageComponent={<Nightclub
                        className='w-[1.143rem] h-[1.143rem]'
                    />}
                    text='Night clubs'
                />
                <Tags 
                    ImageComponent={<Spiritual
                        className='w-[1.143rem] h-[1.143rem]'
                    />}
                    text='Spiritual'
                />
                <Tags 
                    ImageComponent={<Hill
                        className='w-[1.143rem] h-[1.143rem]'
                    />}
                    text='Hill'
                />
                <Tags 
                    ImageComponent={<BeachLanding
                        className='w-[1.143rem] h-[1.143rem]'
                    />}
                    text='Beach'
                />
            </div>
        </div>
        
    </div>
)
}

export default ExploreDestinationListView
