import { messaging } from './firebaseConfig';

export async function requestNotificationPermission() {
  try {
    const token = await messaging.getToken({
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY, // Replace with your VAPID key
    });
    console.log("Notification permission granted and token is: ", token);

    // Save the token to the backend
    await saveTokenToDatabase(token);
  } catch (err) {
    console.error("Error requesting notification permission: ", err);
  }
}

export async function saveTokenToDatabase(token) {
  try {
    const response = await fetch('/api/save-token', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token }),
    });
    if (!response.ok) {
      throw new Error('Failed to save token to database');
    }
    console.log('Token saved to database successfully');
  } catch (error) {
    console.error('Error saving token to database:', error);
  }
}
