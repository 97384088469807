import React from 'react';

const PackingGuideImageBox = ({ imageComponent, text }) => {
  return (
    <div className="bg-white rounded-[10px] overflow-hidden border-[1.4px] border-[#9AA8BC4D] h-[6.25rem] w-[6.25rem] shadow-[#0A32AB1A] shadow-lg">
    
      <div className="mt-2 ml-[25%]">{imageComponent}</div>
      <div className=" w-full">
        <p className="text-sm font-poppins mt-2 font-medium text-center">{text}</p>
      </div>
    </div>
  );
};

export default PackingGuideImageBox;
