import React from 'react'

const Tags = ({tag}) => {
  return (
    <div>
      <div className='w-max rounded-3xl border-[#B3ADAD] border-[1px] text-center font-dm-sans font-medium text-base py-1 px-2 cursor-pointer'>
            <p>{tag}</p>
    </div>
    </div>
  )
}

export default Tags
