import React from 'react';

const Score = ({ starRating, activitySymbol, activity, bgColor = "#F7F4EF" }) => {
  return (
    <div
      className={`w-max p-4 h-max cursor-default rounded-[20px] font-dm-sans font-medium`}
      style={{ backgroundColor: bgColor }}
    >
      <div className="flex justify-center gap-x-1">
        <div className="w-6 h-6">{activitySymbol}</div>
        <p className="text-[22.4px]"> {starRating}</p>
      </div>
      <div className="flex justify-center gap-x-3">
        <div>
          <p className="text-base">{activity}</p>
        </div>
      </div>
    </div>
  );
};

export default Score;
