import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import compassCircle from "../../assets/Compass-circle.svg";
import { ReactComponent as CompassPointer } from "../../assets/Pointers.svg";
import TripType from './TripType';

const Compass = () => {
  const [showCompass, setShowCompass] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (showCompass) {
      const timer = setTimeout(() => {
        navigate('/explore-destination'); // Navigate to /city-explorer after 5 seconds
      }, 5000);

      return () => clearTimeout(timer); // Cleanup timeout on component unmount
    }
  }, [showCompass, navigate]);

  const handleBackToPreferences = () => {
    setShowCompass(false);
  };

  return (
    <>
      {showCompass && (
        <div className='rounded-xl border-[1.4px] border-[#9AA8BC4D] w-[30rem] ml-[6rem] p-4 h-full mb-9'>
          <div className='relative flex justify-center items-center' style={{
            backgroundImage: `url(${compassCircle})`,
            backgroundSize: "auto 100%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "10rem",
            marginTop: "3rem"
          }}>
            <CompassPointer className='absolute h-12 w-16 transform animate-rotate' style={{ transform: 'translate(13rem, 6rem)' }} />
          </div>
          <div>
            <p className='font-plus-jakarta-sans font-semibold text-xl ml-12 mt-6'>
              We're finding destinations for you
            </p>
          </div>
          <div>
            <p className='font-dm-sans font-normal cursor-pointer hover:text-[#1C1C1C] text-[#B3ADAD] ml-32 mt-6' onClick={handleBackToPreferences}> 
              &lt; Back to preferences
            </p>
          </div>
        </div>
      )}
      {!showCompass && <TripType />}
    </>
  );
}

export default Compass;
