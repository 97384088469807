import React from 'react';

const RemoveConfirmationBox = ({ onConfirm, onCancel }) => {
    return (
        <div className='absolute right-10 top-0 shadow-2xl rounded-lg bg-white z-10'>
            <div className='w-[16rem] h-[9rem] text-center font-dm-sans font-normal text-base p-4'>
                <p>Are you sure to delete this card from your itinerary?</p>
                <div className='flex gap-2 justify-center mt-4'>
                    <button
                        className='w-[6.938rem] h-[2.75rem] text-center bg-[#D9D9D9] rounded-[100px] font-dm-sans font-medium text-base'
                        onClick={onCancel}
                    >
                        Not now
                    </button>
                    <button
                        className='w-[6.938rem] h-[2.75rem] text-center bg-[#FFC34F] rounded-[100px] font-dm-sans font-medium text-base'
                        onClick={onConfirm}
                    >
                        Remove
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RemoveConfirmationBox;
