import React, { useState } from 'react';
import PlacenameTag from './PlacenameTag';
import RemoveConfirmationBox from '../../components/ItineraryPlanner/RemoveConfirmationBox';

import { ReactComponent as Clock } from "../../assets/clock.svg";
import { ReactComponent as Trash } from "../../assets/Trash.svg";
import { ReactComponent as Details } from "../../assets/details.svg";

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
};

const DayDetail = ({ date, activities }) => {
    const [showConfirmation, setShowConfirmation] = useState(null);

    const toggleConfirmation = (index) => {
        setShowConfirmation(showConfirmation === index ? null : index);
    };

    const handleDelete = () => {
        setShowConfirmation(null); // Hide the confirmation box after deletion
    };

    return (
        <div className='flex flex-col h-[38.5rem] overflow-auto p-3'>
            {activities.map((activity, index) => (
                <div key={index} className='relative w-[30rem] h-auto rounded-[10px] shadow-xl mt-4 p-4 cursor-pointer'>
                    <div className='flex justify-between mt-2'>
                        <div>
                            <p className='font-plus-jakarta-sans font-semibold text-xl'>
                                {formatDate(date)} - {activity['sub-header']}
                            </p>
                            <div className='flex gap-2'>
                                <Clock className='w-[0.813rem] h-[0.813rem] mt-2' />
                                <div>
                                    <p className='font-dm-sans font-medium text-base mt-1'>
                                        {activity.time} mins
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-between mt-4'>
                        <div className='flex gap-[9px]'>
                        </div>
                        <div>
                            <Details className='w-6 h-6 cursor-pointer' />
                        </div>
                    </div>
                    <div className='flex justify-between mt-4'>
                        <div>
                            <ul className='flex gap-4'>
                                <li>
                                    <PlacenameTag placename='Holiday Street' />
                                </li>
                                <li>
                                    <PlacenameTag placename='Anjuns Market' />
                                </li>
                            </ul>
                        </div>
                        <div
                            className='flex gap-1 cursor-pointer'
                            onClick={() => toggleConfirmation(index)} 
                        >
                            <Trash className='w-[18px] h-[19.5px] mt-[0.1rem]' />
                            <p className='font-poppins font-medium text-base text-[#8F8F8F]'>
                                Delete
                            </p>
                        </div>
                    </div>
                    {showConfirmation === index && ( // Render confirmation box if showConfirmation matches the current index
                        <RemoveConfirmationBox
                            onConfirm={handleDelete}
                            onCancel={() => toggleConfirmation(index)}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};

export default DayDetail;
