import React from 'react'
import { ReactComponent as CancleIcon } from "../../assets/X.svg";

const FilterAndActivites = ({title , items , onClose}) => {
  return (
    <div className='h-full w-[25.063rem] bg-white p-4 '>
    <div className='flex justify-between'>
        <p className='font-plus-jakarta-sans font-semibold text-[32px]'>{title}</p> 
        <div className='w-12 h-12 mt-4 cursor-pointer' onClick={onClose}>
          <CancleIcon /> 
        </div>
      
    </div>  
      <ul className='list-none'>
        {items.map((item , index) => (
          <li key={index} className='mt-2'>
              {item}
          </li>
        ))}
      </ul>

    </div>
  )
}

export default FilterAndActivites
