import React, { useState } from 'react';

const Box1 = ({ imageComponent, text, onClick }) => {
  const [isHighlighted, setIsHighlighted] = useState(false);

  const handleClick = () => {
    setIsHighlighted(!isHighlighted);    // Toggle the highlight state
    if (onClick) {
      onClick(); 
    }
  };

  return (
    <div 
      className={`rounded-2xl overflow-hidden border-[1.4px] border-[#6f7c944d] h-[7.75rem] w-[7.75rem] cursor-pointer ${
        isHighlighted ? 'bg-[#FFD27A]' : 'hover:bg-[#FFD27A]'
      }`}
      onClick={handleClick}
    >
      <div className="mt-2 ml-[20%]">{imageComponent}</div>
      <div className="w-full">
        <p className="text-[16px] font-dm-sans font-medium text-center">{text}</p>
      </div>
    </div>
  );
};

export default Box1;
