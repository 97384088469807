import React, { useState } from 'react';
import axios from 'axios';
import { ReactComponent as SoloTripImage } from "../../assets/soloTripImage.svg";
import { ReactComponent as FamilyTripImage } from "../../assets/familyTripImage.svg";
import { ReactComponent as GroupTripImage } from "../../assets/groupTripImage.svg";
import { ReactComponent as LocationIcon } from "../../assets/locationIcon.svg";
import Box1 from './Box1';
import PlanMyItineraryBox2 from './PlanMyItineraryBox2';

// Initialize Collections array
export let Collections = [];

const PlanMyItinerary = ({ style }) => {
  const [addTripPressed, setAddtripPressed] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState('');
  const [userLocation, setUserLocation] = useState('');
  const [isLocationDetected, setIsLocationDetected] = useState(false);
  
  const getCurrentLocation = () => 
    {
    if (navigator.geolocation) 
      {
      navigator.geolocation.getCurrentPosition(async (position) => 
        {
        const { latitude, longitude } = position.coords;
        try {
          // Make a call to the Reverse Geocoding API
          const location = await getCityStateCountry(latitude, longitude);
          setUserLocation(location);
          setIsLocationDetected(true);   
        } 
        catch (error) {
          console.error('Error getting location:', error);
        }
      }, (error) => {
        console.error('Error getting coordinates:', error);
      }, {
        enableHighAccuracy: true,    // Request high accuracy
        timeout: 5000,     // Set a timeout 
        maximumAge: 0     // Disable caching of the position 
      });
    } else {
      alert('ERROR!! Geolocation is not supported by your browser.');
    }
  };

  const getCityStateCountry = async (latitude, longitude) => {
    const apiKey = process.env.REACT_APP_OPENCAGE_API;
    const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`;

    const response = await axios.get(apiUrl);
    const data = response.data;

    if (data.results.length > 0) {
      const components = data.results[0].components;
      const city = components.city || components.town || components.village;
      const state = components.state;
      const country = components.country;

      return `${city}, ${state}, ${country}`;
    }
    return 'Location not found!!';
  };

  const handleAddTripClick = () => {
    if (selectedTrip && Collections.length == 0) {
      Collections.push(selectedTrip.toLowerCase());
      console.log('Collections:', Collections); // Log Collections to the console
    }
    if (Collections.length == 0) {
      alert("WARNING : Please select a trip type to generate the itinerary!");
      return;
    }
    setAddtripPressed(true);
  };

  const handleTripSelection = (tripType) => {
    setSelectedTrip(tripType);
    console.log(`Selected Trip: ${tripType}`); // Log the selected trip type
  };

  if (addTripPressed) {
    return <PlanMyItineraryBox2 
      style = {{position: 'absolute' ,marginTop: '1.5rem', left: '13.2rem'}}
    />;
  }

  return (
    <div style={style}>
      <div className='h-[17.95rem] w-[27.27rem] rounded-[15px] border-[1.4px] border-[#080c144d] ml-9'>
        <div className='mt-4'>

        <div className='ml-6 mt-4 flex items-center'>
          <LocationIcon className='w-6 h-6 text-red-600 mr-2 cursor-pointer'/> 
          <p className='text-[#1C1C1C] hover:text-[#ed5050] cursor-pointer'
            onClick={getCurrentLocation}
          >
            Find My Location
            {isLocationDetected && userLocation && (
              <span className='ml-1 font-plus-jakarta-sans font-medium'>
                - {userLocation}
              </span>
            )}
          </p>
        </div>

          <p className='font-plus-jakarta-sans font-semibold text-xl text-left ml-6 mt-2'>How many are you travelling?</p>
          <p className='font-dm-sans font-normal text-sm text-left ml-6 text-[#494A52]'></p>
        </div>
        <div className='flex justify-center gap-4 mt-2'>
          <Box1 imageComponent={<SoloTripImage className='w-16 h-16 align-middle' />} text='Solo Trip' onClick={() => handleTripSelection('Solo Trip')} />
          <Box1 imageComponent={<GroupTripImage className='w-16 h-16 align-middle' />} text='Group Trip' onClick={() => handleTripSelection('Group Trip')} />
          <Box1 imageComponent={<FamilyTripImage className='w-16 h-16 align-middle' />} text='Family Trip' onClick={() => handleTripSelection('Family Trip')} />
        </div>
        <div className=''>
          <button
            className='w-[25.55rem] h-9 bg-[#1C1C1C] hover:bg-[#FFD27A] text-white hover:text-[#1C1C1C] rounded-2xl text-left px-4 mt-5 ml-3'
            onClick={handleAddTripClick}
          >
            <p className=''>Add trip details</p>
          </button>
          <p className='translate-x-[85%] -translate-y-[120%] text-white hover:text-[#1C1C1C]'>&gt;</p>
        </div>
      </div>
    </div>
  );
};

export default PlanMyItinerary;
