import React from 'react';

const GlobalSearchList = ({ icon, task, onClick, isSelected }) => {
  return (
    <div 
      className={`w-[11.375rem] p-4 rounded-2xl flex gap-2 bg-white ${isSelected ? 'bg-[#F3F7FF]' : 'hover:bg-[#F3F7FF]'}`}
      onClick={onClick}
    >
      <div className='w-6 h-6'>{icon}</div>
      <p className='font-plus-jakarta-sans font-semibold text-base'>{task}</p>
    </div>
  );
};

export default GlobalSearchList;
