import { useState } from 'react';
import { supabase } from '../../utils/supabaseClient';
import { useNavigate } from 'react-router-dom';

const Signup = () => 
{
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { email, password } = formData;

        if (!validateEmail(email)) {
            setErrorMessage("Invalid Email format!!");
            return;
        }

        if (password.length < 6) {
            setErrorMessage("Password must be at least 6 characters long!!");
            return;
        }

        setLoading(true);
        setErrorMessage("");

        try {
            const { error } = await supabase.auth.signUp({
                email,
                password,
            });

            if (error) throw error;

            alert("Signup Done Successfully!");

            // Redirect to home page
            navigate('/');

        } catch (error) {
            setErrorMessage(error.message);  // Display error message in UI
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleSignUp = async () => {
        try {
            const { error } = await supabase.auth.signInWithOAuth({
                provider: 'google',
            });

            if (error) throw error;

        } catch (error) {
            console.error('Error with Google sign-in:', error.message);
            alert('Google SSO Log In failed!!');
        }
    };

    return (
        <div className="flex justify-center items-center h-screen bg-white">
            <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-2xl">
                <h2 className="text-3xl font-bold text-center mb-6">Sign Up</h2>

                {errorMessage && 
                <p className="text-red-500 mb-4 text-center">{errorMessage}</p>}
                
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-900 font-medium mb-2">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            aria-label="Email"
                            className="w-full px-4 py-2 border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FFC34F]"
                            placeholder="Enter your email"
                        />
                    </div>

                    <div className="mb-6 relative">
                        <label htmlFor="password" className="block text-gray-900 font-medium mb-2">
                            Password
                        </label>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                            aria-label="Password"
                            className="w-full px-4 py-2 border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FFC34F]"
                            placeholder="Enter your password"
                        />
                        <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute mb-1 top-1/2 transform inset-y-0 right-3 flex items-center text-gray-500"
                        >
                            {showPassword ? "Hide" : "Show"}
                        </button>
                    </div>

                    <button
                        type="submit"
                        disabled={loading}
                        className={`w-full bg-[#FFC34F] text-black font-medium py-2 px-4 rounded-md hover:bg-black hover:text-white transition duration-200 
                        ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
                    >
                        {loading ? "Signing Up..." : "Sign Up"}
                    </button>
                </form>

                    <div className="mt-4">
                        <button
                            onClick={handleGoogleSignUp}
                            className="w-full bg-[#FFC34F] text-black font-medium py-2 px-4 rounded-md hover:bg-black hover:text-white transition-colors"
                        >
                            Sign Up with Google SSO
                        </button>
                    </div>

                    <p className="text-center text-gray-900 font-medium mt-4">
                        Already have an account?{' '}
                        <a href="/log-in" className="text-[#edaf34] hover:underline">
                            Log In
                        </a>
                    </p>
            </div>
        </div>
    );
};

export default Signup;
