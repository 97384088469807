import React, { useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import EventIcon from '@mui/icons-material/Event';
import Checkbox from '@mui/material/Checkbox';
import PreferencesBox from './PreferencesBox';
import DatePicker from 'react-datepicker';


const NewExploreDestinationMobileView = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    return (
    <div className='w-[398px] h-[535px] rounded-[10px] bg-white font-plus-jakarta-sans font-medium p-4'>
        <p className='text-[#797676] text-xs'>
            Step 1 of 2
        </p>

        <p className='text-2xl text-[#3D4F67] mt-3'>
            Select your preferences
        </p>
        <div className='w-[366px] h-[148px] mt-3'>
        <p className='font-bold text-sm text-[#484646]'>Sarting from</p>
        <input 
            className="border-[1px] border-black rounded-[10px] w-[366px] h-[56px] flex p-3 cursor-pointer"
            
            placeholder= "Destination"
        />
        
        <div className='flex gap-x-1 mt-3'>
        <div className='border-[1px] border-black rounded-[10px] w-[222px] h-[56px] flex p-3 gap-x-2'>
            <div>
                <PersonIcon />
                
            </div>
            <div className='w-[54px] h-[30px] bg-[#FFEDD0] rounded-[10px] text-center cursor-pointer'>
                    -
                </div>
                <p>01</p>
                <div className='w-[54px] h-[30px] bg-[#FFB543] rounded-[10px] text-center cursor-pointer'>
                    +
                </div>
        </div>
        <div className="flex">
            <Checkbox defaultChecked color="default" />
            <p>Travelling from multiple cities?</p>
        </div>
        </div>
        </div>
        <div className='h-[112px]'>
        <p className='font-bold text-sm text-[#484646] mt-3'>Duration</p>
        <div className="flex mt-3">
            <div className="w-[180px] h-[56px] border-[1px] border-black rounded-[10px] p-3  flex cursor-pointer">
                <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="dd/MM/yyyy"
                placeholderText="Start Date"
                showYearDropdown
                showMonthDropdown
                popperPlacement="right"
                className="text-dm-sans font-normal text-[#131a23] w-full"
                />
                <EventIcon />
            </div>
            <div className="w-[180px] h-[56px] border-[1px] border-black rounded-[10px] p-3 ml-3 flex cursor-pointer">
                <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="dd/MM/yyyy"
                placeholderText="End Date"
                showYearDropdown
                showMonthDropdown
                minDate={startDate}
                popperPlacement="right"
                className="text-dm-sans font-normal text-[#131a23] w-full"
                />
                <EventIcon />
            </div>
        </div>
        <div className="flex mt-2">
            <Checkbox defaultChecked color="default" />
            <p className='mt-2'>Not sure? Let us help</p>
        </div>
    </div>
    <div className='h-[68px] w-[293px] mt-7'>
    <p className='font-bold text-sm text-[#484646]'>Location Preferences</p> 
    <div className="flex gap-x-2 mt-2">
        <PreferencesBox text='Domestic'/>
        <PreferencesBox text='International'/>
        <PreferencesBox text='Both'/>
    </div>
    </div>
    <div className="flex mt-3 gap-x-2  cursor-pointer ">
          <div className="border-[1px] border-[#FFB543] rounded-[10px] text-center w-[179px] h-[40px]">
            <p className="mt-2">Back</p>
          </div>
          <div className="h-[40px] w-[179px] bg-[#FFB543] text-black text-center rounded-[10px]">
            <p className="mt-2"> Continue</p>
          </div>
        </div>
    </div>
  )
}

export default NewExploreDestinationMobileView