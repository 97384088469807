import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Clock } from "../../assets/Clock1.svg";
import { ReactComponent as LightHouse } from "../../assets/Lighthouse.svg";
import { ReactComponent as Ticket } from "../../assets/Ticket.svg";

const SelectedCardModal = ({ selectedCards, onClose }) => {
    const navigate = useNavigate();
    const handleAddItinery = () => {
        navigate('/ai-travel-itinerary')
    }
    return (
    
        <div className='h-[40.5rem] w-[49.938rem] shadow-lg overflow-y-scroll overflow-x-hidden fixed top-[5%] z-20 bg-white cursor-default rounded-lg p-4'>
            <div className='flex justify-between font-plus-jakarta-sans font-semibold text-xl '>
            
            <div className='flex gap-x-3 cursor-pointer'>
                <p onClick={onClose}>
                    &gt;
                </p>
                <p>
                    {selectedCards.length} Selected
                </p>
                </div>
                <div className='w-[7.438rem] h-7 px-3 py-1 font-medium font-dm-sans text-sm bg-[#FFC34F] rounded-xl cursor-pointer' onClick={handleAddItinery}>
                    <p>Add to itinery</p>
                </div>
            </div>
            <ul>
            {selectedCards.map((card, index) => (
                <li key= {index}> 

                    <div className='w-[47.5rem] h-[12.825rem] p-3 flex justify-start gap-x-6 border-black rounded-lg shadow-lg gap-y-3 mt-5 ml-'>
                        <div className=''>
                    {card.imageComponent}
                    </div>
                        <div className='flex flex-col gap-y-1'>
                        <p className='font-poppins font-medium text-xl'>
                            {card.placename}
                        </p> 
                        <div className='flex p-2 gap-2'>
                            <Clock className='h-5 w-5' />
                            <p className='font-poppins font-medium text-sm text-[#1C1C1C]'>Duration:</p>
                            <p className='font-poppins font-semibold text-sm text-[#1C1C1C]'>{card.travelTime}</p>
                        </div>
                        <div className='flex gap-x-3'>
                        <div className='w-max text-sm h-full p-2 bg-[#F3EAD7] flex gap-2 rounded-lg'>
                            <LightHouse className='h-5' />
                            <div>{card.placeType}</div>
                        </div>
                        <div className='p-2 bg-[#F3EAD7] w-max h-full flex rounded-lg gap-4'>
                            <Ticket className='' />
                            <p className='font-poppins font-semibold text-[#1C1C1C] text-xs'>{card.ticket} Tickets</p>
                        </div>
                    </div>
                    </div>
                    </div>
                </li>
            ))}
            </ul>
        </div>
    );
};

export default SelectedCardModal;
