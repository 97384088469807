import React from 'react'

const CityFamousActivities = ({symbol, activity}) => {
  return (
    <div className=' bg-[#FFFAF1] w-max h-max font-dm-sans font-medium text-base flex flex-col justify-center items-center gap-y-2 p-4 rounded-[20px]'>
      <div className='w-[21px] h-[19.5px]'>
        {symbol}
      </div>
      <p>
        {activity}
      </p>
    </div>
  )
}

export default CityFamousActivities
