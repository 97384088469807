import React, { useState } from 'react';
import CityPlace  from "../../assets/cityPlace.svg";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';

const NewCityPlaceCard = ({placename , travetime , onSelection}) => {
    const [isSelected, setIsSelected] = useState(false);

    const handleCheckboxChange = () => {
        setIsSelected(!isSelected);
        onSelection({  placename, travetime }); // Pass card details to parent component
    };
  return (
    <div className='w-[294px] h-[312px] rounded-[10px] font-plus-jakarta-sans font-medium p-3'
     style={{
        
        backgroundImage: `url('${CityPlace}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}>
        <div className='bg-white rounded-[10px] p-2 mt-[70%]'>
            <div>{placename}</div>
            <div className='flex gap-x-1'>
                
                <div><AccessTimeIcon /></div>
                <div>
                    <p className='text-[#ADAAAA] text-sm flex gap-x-1'>Duration: <p className='text-black'>{travetime}</p>
                    </p>
                </div>
                
            </div>

            <div className='flex justify-between '>
                <div className='flex gap-x-2'>
                    <div className='w-12 h-9 rounded-[10px] bg-[#E4E9EF] px-3 py-1'>
                        <CameraAltIcon />
                    </div>
                    <div className='w-12 h-9 rounded-[10px] bg-[#E4E9EF] px-3 py-1'>
                        <BeachAccessIcon />
                    </div>
                    <div className='w-12 h-9 rounded-[10px] bg-[#E4E9EF] px-3 py-1'>
                        <BeachAccessIcon />
                    </div>
                </div>
                <label className="flex items-center">
                    <input
                        type="checkbox"
                        className="form-checkbox h-6 w-6 bg-black text-black rounded-full focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mr-1"
                        checked={isSelected}
                        onChange={handleCheckboxChange}
                    />
                    
                </label>
            </div>
        </div>
    </div>
  )
}

export default NewCityPlaceCard