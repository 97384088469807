import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import { ReactComponent as CalenderIcon } from "../../assets/calenderGrey.svg";
import PlanMyItinerary, { Collections } from './PlanMyItinerary';
import { axiosInstance } from '../../config';
import LineLoader from './LineLoader';

export let MapDestination = ''; 

const PlanMyItineraryBox2 = ({ style }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [destination, setDestination] = useState('');
  const [backPressed, setBackPressed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [flag, setFlag] = useState(0);
  const navigate = useNavigate();
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (destination.length > 0) {
      const fetchSuggestions = async () => {
        try {
          const response = await axiosInstance.get('/search', {
            params: { q: destination },
          });

          const hits = response.data.hits;

          const suggestions = hits.map(hit => {
            // Check for 'CITY_NAME', 'CNTRY_NAME' in order of preference
            if (hit.properties.CITY_NAME) {
              return hit.properties.CITY_NAME;
            } 
            if (hit.properties.CNTRY_NAME) {
              return hit.properties.CNTRY_NAME;
            } 
            else {
              return '';  // Default to an empty string if none of the fields are available
            }
          }).filter(suggestion => suggestion !== '');     // Filter out any empty strings

          setSuggestions(suggestions);
        } catch (error) {
          console.error('Error fetching suggestions from Algolia:', error);
        }
      };

      fetchSuggestions();
    } else {
      setSuggestions([]);      // Clear suggestions if the input field is empty
    }
  }, [destination]);

  useEffect(() => {      // Event listener to detect clicks outside of the suggestion list
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setFlag(1); 
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handlebackClick = () => {
    setBackPressed(true);
  };


  const handleCreateItineraryClick = async () => {
    if (destination) {
      Collections.push(destination.toLowerCase());
      MapDestination = destination;      // Stores the destination for the map
      console.log('Destination added to Collections:', destination.toLowerCase());
    }
    if (startDate) {
      let startDateString = `${startDate.getMonth()+1}/${startDate.getDate()}/${startDate.getFullYear()}`
      Collections.push(startDateString);
      console.log('Start date added to Collections:', startDateString);
    }
    if (endDate) {
      let endDateString = `${endDate.getMonth()+1}/${endDate.getDate()}/${endDate.getFullYear()}`
      Collections.push(endDateString);
      console.log('End date added to Collections:', endDateString);

      console.log('Collections after adding itinerary details:', Collections);
    }

    if (!destination || !startDate || !endDate) {
      Collections.splice(1);
      console.log('Revised Collections:', Collections);
      alert("WARNING : Please fill in all the fields to generate the itinerary!");
      return;
    }

    try {
      setLoading(true);
      const response = await axiosInstance.post('/send-itinerary', { collections: Collections });
      navigate('/ai-travel-itinerary', { state: { itineraryData: response.data } });
    } 
    catch (error) {
      console.error('Error sending itinerary data:', error);
      alert("ERROR : Generating itinerary failed. Please try again after some time!");
    } 
    finally {
      setLoading(false);
    }
  };

  // Event handler for suggestion click
  const handleSuggestionClick = (suggestion) => {
    setDestination(suggestion);
    setSuggestions([]);       // Clear suggestions after selecting one
  };


  if (backPressed) {
    return <PlanMyItinerary 
      style={{ position: 'absolute', top: '30rem', left: '10rem' }} 
    />;
  }

  return (
    <div style={style}>
    {loading && <LineLoader />}
      <div className='w-[23.375rem] h-[18.875rem] border-[1.4px] border-[#080c144d] rounded-[15px]'>
        <div className='mt-3'>
          <p className='font-plus-jakarta-sans font-semibold text-xl text-left ml-6'>Where are you travelling?</p>
        </div>

        <div className='flex p-3'>
          <input
            type='text'
            placeholder='Search Destination'
            value={destination}
            onChange={(e) => {
              setDestination(e.target.value);
              setFlag(0);
            }}
            className='w-[20.875rem] h-12 rounded-[10px] border-[1px] border-[#080c144d] ml-3 px-2'
          />
          <SearchIcon className='absolute left-80 mt-3' />
        </div>

      {/* Dynamic Suggestions */}
      <div ref={wrapperRef}>
        {destination.length > 0 && flag === 0 && (
          <ul className='absolute z-10 bg-white border border-gray-500 rounded-md w-[20rem] ml-7 p-2'>
            {suggestions.map((suggestion, index) => (
              <li 
                key={index} 
                onClick={() => {
                  setFlag(1);
                  handleSuggestionClick(suggestion); 
                }} 
                className='cursor-pointer hover:bg-[#FFC34F] p-2 rounded-md'
              >
              {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Static Suggestions */}
      <div className='w-[20.875rem] h-[2.5rem] flex flex-wrap ml-6 mb-2'>
        <div 
          className='w-[3.75rem] h-[2.5rem] flex bg-[#FFC34F] rounded-xl m-1 cursor-pointer'
          onClick={() => {
            setFlag(1);
            handleSuggestionClick('Goa'); 
          }}>
          <p className='font-dm-sans font-medium text-base ml-3 mt-2'>
            Goa
          </p>
        </div>
        <div 
          className='w-[5.25rem] h-[2.5rem] flex bg-[#FFC34F] rounded-xl m-1 cursor-pointer' 
          onClick={() => {
            setFlag(1);
            handleSuggestionClick('London'); 
          }}>
          <p className='font-dm-sans font-medium text-base ml-3 mt-2'>
            London
          </p>
        </div>
        <div 
          className='w-[4rem] h-[2.5rem] flex bg-[#FFC34F] rounded-xl m-1 cursor-pointer' 
          onClick={() => {
            setFlag(1);
            handleSuggestionClick('Paris'); 
          }}>
          <p className='font-dm-sans font-medium text-base ml-3 mt-2'>
            Paris
          </p>
        </div>
        <div 
          className='w-[4.5rem] h-[2.5rem] flex bg-[#FFC34F] rounded-xl m-1 cursor-pointer' 
          onClick={() => {
            setFlag(1);
            handleSuggestionClick('Rome'); 
          }}>
          <p className='font-dm-sans font-medium text-base ml-3 mt-2'>
            Rome
          </p>
        </div>
      </div>

        <div className='flex p-3'>
          <div className='w-[10.25rem] h-12 border-[1px] border-[#080c144d] rounded-[10px] p-3 ml-3'>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="Start Date"
              showYearDropdown
              showMonthDropdown
              minDate={new Date()}
              maxDate={endDate}
              popperPlacement="left"
              className='text-dm-sans font-normal text-[#131a23] w-full'
            />
            <CalenderIcon className='absolute left-[9.5rem] -translate-y-[1.5rem]' />
          </div>
          <div className='w-[10.25rem] h-12 border-[1px] border-[#080c144d] rounded-[10px] p-3 ml-3'>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="End Date"
              showYearDropdown
              showMonthDropdown
              minDate={startDate}
              popperPlacement="right"
              className='text-dm-sans font-normal text-[#131a23] w-full'
            />
            <CalenderIcon className='absolute left-[20.5rem] -translate-y-[1.5rem]' />
          </div>
        </div>
        
        <div className='flex justify-center gap-2 cursor-pointer'>
          <div className='w-11 h-9.75 bg-[#F3EAD7] text-center rounded-xl text-base mt-1 ml-1 cursor-pointer' onClick={handlebackClick}>
            <p className='mt-2 '>&lt;</p>
          </div>

        <button
          className={`bg-[#1C1C1C] text-white hover:text-[#1C1C1C] hover:bg-[#FFC34F] w-[17.375rem] h-9 rounded-xl px-3 text-left font-dm-sans text-base font-medium mt-1 ${loading ? 'cursor-not-allowed opacity-70 text-[#1C1C1C] bg-[#FFC34F]' : ''}`}
          onClick={handleCreateItineraryClick}
          disabled={loading}
          >
          <span className={loading ? 'text-[#1C1C1C]' : ''}>
            {loading ? 'Creating...' : 'Create my itinerary'}
          </span>
        </button>

          <p className='absolute text-white left-[20rem] mt-[0.7rem] text-base hover:text-[#1C1C1C]'>
            &gt;
          </p>
        </div>
      </div>
    </div>
  );
};

export default PlanMyItineraryBox2;
