import React , {useState} from 'react'
import DatePicker from 'react-datepicker';
import { ReactComponent as CalenderIcon } from "../../assets/calenderGrey.svg";
import { ReactComponent as DeckChair } from "../../assets/deckChair.svg";
const ModifyDate = () => {

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    return (
        <div className='w-[36rem] h-[13rem] rounded-[10px] p-5 bg-white mt-12 ml-16 shadow-[#0A32AB1A] shadow-xl '>
            <div className='flex items-center gap-4'>
                <DeckChair className='w-8 h-8'/>
                <p className='font-plus-jakarta-sans font-semibold text-xl leading-none'>
                    Weather looks good!
                </p>
            </div>

            <div className='mt-4'>
                <p className='font-dm-sans font-normal text-sm'>
                    Avg. Tempreature is between 20ºC to 30ºC for the time you’re travelling
                </p>
            </div>

            <div>
                <p className='font-dm-sans font-medium text-base mt-4'>Modify Dates</p>
            </div>

            <div className='flex p-3'>
                <div className='w-[18rem] h-12 border-[1px] border-[#080c144d] rounded-[10px] p-3'>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Start Date"
                        showYearDropdown
                        showMonthDropdown
                        minDate={new Date()}
                        maxDate={endDate}
                        popperPlacement="left"
                        className='text-dm-sans font-normal text-[#131a23] pl-9 w-full'
                    />
                    <CalenderIcon className='absolute -translate-y-[1.5rem]'/>     
                </div>
                <div className='w-[18rem] h-12 border-[1px] border-[#080c144d] rounded-[10px] p-3 ml-3'>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="End Date"
                        showYearDropdown
                        showMonthDropdown
                        minDate={startDate}
                        popperPlacement="right"
                        className='text-dm-sans font-normal text-[#131a23] pl-9 w-full'
                    />
                    <CalenderIcon className='absolute -translate-y-[1.5rem]'/>
                </div> 
            </div>
        </div>
    )
}

export default ModifyDate
