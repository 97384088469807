import React , {useState , useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Plane } from "../../assets/plane.svg";
import { ReactComponent as Calender } from "../../assets/calenderBlack.svg";



const ExploreDestinationCardView = ({imageComponent , placename, travelTime, tag, placeType , travelMonth}) => {
    const navigate = useNavigate();
    const handlePlanYourTrip = () => {
        navigate('/city-explorer')
    }

  return (
    <div className='w-[285.67px] h-[336.95px] shadow-lg rounded-xl'>
        <div className='w-[17.196rem] h-[9.7rem]'>
            {imageComponent}
        </div>
        <div>
            <p className='px-2 mt-1'>{placename}</p>
        </div>
        <div className='flex p-2 gap-2'>
            <Plane className='h- w-5 '/>
            <p className='font-poppins  font-medium text-sm text-[#1C1C1C]'>Travel Time: </p>
            <p className='font-poppins font-semibold text-sm text-[#1C1C1C]'>{travelTime}</p>
        </div>
        <div className='flex gap-1 p-3'>
            <div className='w-[5.5rem] text-sm  h-full p-2 bg-[#F3EAD7] flex gap-2 rounded-lg'>
            <div>{tag}</div>
                
            <div>{placeType}</div> 
            </div>

            <div className='p-1 bg-[#F3EAD7] w-[12rem]  h-full flex rounded-lg gap-4'>
                <Calender className='h-6'/>
                <p className='font-poppins font-semibold text-[#1C1C1C] text-xs'>{travelMonth}</p>
            </div>
        </div>
        <div className=''>
        <button className='w-[16.8rem] h-11 ml-2  rounded-lg  text-[#1C1C1C] text-center bg-[#FFD27A] font-poppins font-medium hover:bg-[#1C1C1C] hover:text-[#ffff]' onClick={handlePlanYourTrip}>
            Plan your trip
        </button>
        </div>
    </div>
  )
}

export default ExploreDestinationCardView
