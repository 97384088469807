import React  from "react";

const ReviewCard = ({ description, userImage, userName, userDescription, userLocation, starImage }) => {

return (
    <div className="bg-white rounded-2xl  overflow-hidden w-full lg:w-[32.5rem] h-[17.5rem] p-5 lg:p-4 mt-6 lg:mt-0">
    <div className="w-[18px] lg:w-5 ">{starImage}</div>
        <div className="">
        <p className="mt-4 lg:mt-5 lg:ml-5  lg:mr-5 font-Inter font-normal text-base lg:text-md ">{description}</p>
        </div>

        <div className="flex mt-2 lg:mt-5 lg:ml-5 lg:gap-x-5 ">
            
            <div>
                <div className="font-bold lg:ml-2">{userName}</div>
                <div className="font-light lg:ml-2 text-[#505050]">{userDescription}</div>
                <div className="font-light lg:ml-2 text-[#505050]">{userLocation}</div>
            </div>
            <div className="lg:ml-40">{userImage}</div>
        </div>
    </div>
    
);
};

export default ReviewCard;
