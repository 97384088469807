import React from 'react'
import { ReactComponent as Plane } from "../../assets/plane.svg";
import { ReactComponent as Calender } from "../../assets/calenderBlack.svg";



const EuropeEuropeNew = ({imageComponent , placename, travelTime, tag, placeType , travelMonth}) => {
  return (
    <div className='w-full lg:w-[26.813rem] p-2 lg:p-0 lg:h-[32.625rem] rounded-[1.25rem] shadow-xl  '>
        <div className='p-3 mt-1 w-full lg:w-[25.375rem] h-[15rem] lg:h-[16.813rem]'>
            {imageComponent}
        </div>
        <div>
            <p className='font-poppins font-semibold text-base lg:text-lg text-[#1C1C1C] mt-[5%] lg:mt-4 ml-4 lg:ml-0 lg:p-3'>{placename}</p>
        </div>
        <div className='flex p-2 ml-1 lg:ml-0 lg:p-3 gap-2'>
            <Plane className='h-6 w-6 '/>
            <p className='font-poppins  font-medium text-sm lg:text-md text-[#1C1C1C]'>Travel Time: </p>
            <p className='font-poppins font-semibold text-sm lg:text-md text-[#1C1C1C]'>{travelTime}</p>
        </div>
        <div className='flex gap-1 p-2 lg:p-3'>
            <div className='lg:w-[6rem] h-full p-2 bg-[#F3EAD7] flex gap-2 rounded-lg'>
            <div>{tag}</div>
                
            <div>{placeType}</div> 
            </div>

            <div className='p-2 bg-[#F3EAD7] lg:w-[17rem]  h-full flex rounded-lg gap-4'>
                <Calender className='h-6'/>
                <p className='font-poppins font-semibold text-[#1C1C1C] text-sm text-md'>{travelMonth}</p>
            </div>
        </div>
        <div className='p-1'>
        <button className='w-full lg:w-[25rem] h-11 lg:m-1 rounded-lg  text-[#1C1C1C] text-center bg-[#FFD27A] font-poppins font-medium hover:bg-[#1C1C1C] hover:text-[#ffff]'>
            Plan your trip
        </button>
        </div>
    </div>
  )
}

export default EuropeEuropeNew
