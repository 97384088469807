import React, { useState, useEffect, useRef} from 'react';
import { useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { FiDownload } from 'react-icons/fi';
import jsPDF from 'jspdf';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share';       // Social Links
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'; 

// SVG imports as React Components
import { ReactComponent as Printer } from "../../assets/Printer.svg";
import { ReactComponent as Share } from "../../assets/ShareNetwork.svg";
import { ReactComponent as AddPlan } from "../../assets/addPlan.svg";
import { ReactComponent as BeachTowel } from "../../assets/beachTable.svg";
import { ReactComponent as BeachBag } from "../../assets/beachBag.svg";
import { ReactComponent as WaterBottle } from "../../assets/waterBottle.svg";
import { ReactComponent as Charger } from "../../assets/charger.svg";
import { ReactComponent as Camera } from "../../assets/camera.svg";
import { ReactComponent as PowerBank } from "../../assets/powerBank.svg";
import { ReactComponent as Cash } from "../../assets/card.svg";
import { ReactComponent as TravelGuide } from "../../assets/travelGuide.svg";
import { ReactComponent as FirstAid } from "../../assets/firstAid.svg";

// Component Imports
import Day from '../../components/ItineraryPlanner/Day';
import DayDetail from '../../components/ItineraryPlanner/DayDetail';
import PackingGuides from '../../components/ItineraryPlanner/PackingGuides';
import StayButton from '../../components/ItineraryPlanner/StayButton';
import StayDetailBox from '../../components/ItineraryPlanner/StayDetailBox';
import Alerts from '../../components/ItineraryPlanner/Alerts';
import ModifyDate from '../../components/ItineraryPlanner/ModifyDate';
import AddPlanDialogBox from '../../components/ItineraryPlanner/AddPlanDialogBox';
import RemoveConfirmationBox from '../../components/ItineraryPlanner/RemoveConfirmationBox';
import { Collections } from '../../components/landingPage/PlanMyItinerary';
import { MapDestination } from '../../components/landingPage/PlanMyItineraryBox2';

const ItineraryPlanner = () => {
  const location = useLocation();
  const { itineraryData } = location.state || {};
  const [parsedData, setParsedData] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [mapSrc, setMapSrc] = useState('');
  const shareUrl = window.location.href;
  const [isSharePopupVisible, setIsSharePopupVisible] = useState(false);

  if(Collections.length > 0) {
    Collections.splice(0);     // Clearing the Collections list if it was already used
  }

  useEffect(() => {
    if (typeof itineraryData === 'string' && itineraryData !== null) {
      console.log('Received Itinerary from backend', itineraryData);
      const JsonData = JSON.parse(itineraryData);
      setParsedData(JsonData);

      if (JsonData.itinerary.length > 0) {
        setSelectedDay(JsonData.itinerary[0].day.split(' ')[1]); // Set default selected day to the first day
      }
    }
  }, [itineraryData]);


  if (MapDestination) {      // Saving MapDestination to local storage for Refresh Persistence
    localStorage.setItem('mapDestination', MapDestination);
  }

  useEffect(() => {
    const savedMapDestination = localStorage.getItem('mapDestination');
    if (savedMapDestination) {       // Google Maps Embed URL generation
      setMapSrc(`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${encodeURIComponent(savedMapDestination)}`);
    }
  }, []);


  const handleDayClick = (dayNumber) => {
    setSelectedDay(dayNumber);
  };

  const toggleSharePopup = () => {
    setIsSharePopupVisible(!isSharePopupVisible);
  };

  const [days, setDays] = useState(parsedData?.itinerary || []);
  const [isDialogBoxVisible, setIsDialogBoxVisible] = useState(false);
  const [isRemoveConfirmationBoxVisible, setIsRemoveConfirmationBoxVisible] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);

  // Drag and Drop Handler
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(days);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setDays(items);
  };

  // Add Plan Dialog Box Handler
  const toggleDialogBox = () => {
    setIsDialogBoxVisible(!isDialogBoxVisible);
  };

  // Remove Confirmation Box Handler
  const toggleRemoveConfirmationBox = (item) => {
    setItemToRemove(item);
    setIsRemoveConfirmationBoxVisible(!isRemoveConfirmationBoxVisible);
  };

  // Confirm the Remove Item
  const confirmRemoveItem = () => {
    setDays(days.filter(day => day.id !== itemToRemove.id));
    setItemToRemove(null);
    setIsRemoveConfirmationBoxVisible(false);
  };

  const printRef = useRef();

  const handlePrint = () => {
    const printContent = printRef.current;
  
    if (printContent) {
      const newWindow = window.open('', '_blank', 'width=900,height=650');
      
      // Start HTML document structure
      newWindow.document.write(`
      <html>
        <head>
          <title>PRINT ITINERARY</title>
          <style>
            body {
              font-family: sans-serif;
              padding: 20px;
              background-color: #f5f5f5;
            }
            .title {
              font-size: 2em;
              font-weight: bold;
              color: #D2691E;    /* Brown color for title */
              text-align: center;
              margin-bottom: 15px;
            }
            .day-section {
              background-color: #ffffff;
              border-radius: 8px;
              padding: 15px;
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
              margin-bottom: 20px;
            }
            .day-title {
              font-weight: bold;
              font-size: 1.5em;
              color: #d32f2f;    /* Red color for day titles */
              border-bottom: 2px solid #d32f2f;
              padding-bottom: 5px;
              margin-top: 10px;
              margin-bottom: 15px;
            }
            .activity {
              margin-left: 20px;
              margin-bottom: 10px;
              font-size: 1.1em;
              color: #000000;     
            }
            .activity strong {
              color: #388e3c;    /* Darker green for activity names */
            }
            .activity .duration {
              color: #000000;  
            }
          </style>
        </head>
      <body>
      <div class="title">TravelmagIQ AI Travel Guide</div>
      `);
  
      // Loop through each day and add it with its details
      parsedData?.itinerary.forEach((dayData) => {
        const dayNumber = dayData.day.split(' ')[1];
        const dayTitle = `<div class="day-title">Day ${dayNumber} - ${dayData.date}</div>`;
        
        // Map through activities and display their details based on the actual structure
        const activities = dayData.activities.map(activity => `
          <div class="activity">
            <strong>${activity['sub-header'] || 'Activity'}</strong><br/>
            ${activity.time ? `Duration: ${activity.time} mins<br/>` : ''}
          </div>
        `).join('');
  
        newWindow.document.write(`
          <div class="day-section">
            ${dayTitle}
            ${activities}
          </div>
        `);
      });
  
      // Close HTML document structure
      newWindow.document.write(`
          </body>
        </html>
      `);
  
      newWindow.document.close();  // Close the document for writing
      newWindow.focus();  // Bring the print window to the front
      newWindow.print();  // Trigger the print dialog
  
      // Optionally close the window after printing
      newWindow.onafterprint = () => {
        newWindow.close();
      };
    }
  };  

  const handleDownloadPDF = () => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = 10; // Margin for the PDF content
    const footerMargin = 20; // Margin for the footer
    let yPosition = margin; // Initial y-axis position
  
    pdf.setFont("helvetica", "normal");
  
    // Add title
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(18);
    pdf.setTextColor(210, 105, 30); // Brown color for title
    pdf.text("TravelmagIQ AI Travel Guide", pageWidth / 2, yPosition+8, { align: 'center' });
    yPosition += 15; // Increased spacing for better layout
  
    // Add itinerary details
    parsedData?.itinerary.forEach((dayData) => {
      const dayNumber = dayData.day.split(' ')[1];
  
      // Check if adding a new section would overflow the current page
      if (yPosition + 20 > pageHeight - footerMargin) {
        pdf.addPage();
        yPosition = margin; // Reset position for new page
      }
  
      // Add Day Title
      pdf.setFontSize(14);
      pdf.setTextColor(211, 47, 47); // Red color for day titles
      yPosition += 10;
      pdf.text(`Day ${dayNumber} - ${dayData.date}`, margin, yPosition);
      pdf.setDrawColor(211, 47, 47);
      pdf.line(margin, yPosition + 2, pageWidth - margin, yPosition + 2); // Underline
      yPosition += 8;
  
      // Add Activities
      dayData.activities.forEach((activity) => {
        if (yPosition + 15 > pageHeight - footerMargin) {
          pdf.addPage();
          yPosition = margin; // Reset position for new page
        }
  
        pdf.setFontSize(12);
        pdf.setTextColor(56, 142, 60); // Darker green for activity names
        pdf.text(`• ${activity['sub-header'] || 'Activity'}`, margin + 5, yPosition);
        yPosition += 6;
  
        if (activity.time) {
          pdf.setFontSize(11);
          pdf.setTextColor(0, 0, 0); // Black for duration
          pdf.text(`   Duration: ${activity.time} mins`, margin + 10, yPosition);
          yPosition += 6;
        }
      });
  
      yPosition += 5; // Add spacing between days
    });
  
    // Save the PDF
    pdf.save('itinerary.pdf');
  };  

  const handleSaveItinerary = async () => {
    try {
        // Prepare the data to be saved
        const itinerarySaveData = {
            supabaseUserId: "user-123", 
            itineraryData: parsedData, 
        };

        // Make an API call to your backend
        const response = await fetch('/api/itineraries/save', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(itinerarySaveData),
        });

        // Handle the response
        if (response.ok) {
            const result = await response.json();
            alert('Itinerary saved successfully!');
        } else {
            const errorData = await response.json();
            alert(`Failed to save itinerary: ${errorData.message}`);
        }
    } catch (error) {
        console.error('Error saving itinerary:', error);
        alert('An error occurred while saving the itinerary.');
    }
  };
  
  // Conditional Rendering
  if (typeof itineraryData !== 'string' || itineraryData === null) 
  { // Render a loading state or a fallback UI
    return (     
      <div className="flex justify-center items-center h-screen m-1.5">
      <h2 className="text-6xl">Loading...</h2>
      </div>
    );
  }
  else {
  return (
    <div>
      <div className='flex justify-center '>
        <div className='w-[50%]' ref={printRef} >
          <div className='flex justify-center mt-5'>

            <div className='text-center ml-[2.8rem]'>
              <p className='font-dm-sans font-medium text-[44px]'>
                Ultimate Goa Experience
              </p>
              <p className='font-dm-sans font-medium text-[24px]'>
                Panjim, Beaches, and complete fun itinerary!
              </p>
            </div>
        
            <div className='flex gap-2 mt-5 mr-4 items-center cursor-pointer'>

              {/* Download Icon */}
              <FiDownload size={22} onClick={handleDownloadPDF}/>

              {/* Printer Icon */}
              <Printer onClick={handlePrint}/>

              {/* Share Icon */}
              <Share onClick={toggleSharePopup}/>

              {/* Share Popup */}
              {isSharePopupVisible && (
                <div className="absolute top-[60px] right-0 bg-white shadow-lg p-4 rounded-md z-50">
                  <p className="font-medium text-gray-700 mb-2">Share this itinerary</p>
                  <div className="flex gap-3">
                    <FacebookShareButton url={shareUrl} quote={"Check out this itinerary!"}>
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>

                    <TwitterShareButton url={shareUrl} title={"Check out this itinerary!"}>
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>

                    <WhatsappShareButton url={shareUrl} title={"Check out this itinerary!"}>
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                  </div>
                </div>
              )}

            </div>

          </div>

          <div className='mb-2'>
            <ul className='flex list-none font-poppins font-medium text-lg justify-center gap-7 mt-4'>
                <li className='hover:bg-[#1C1C1C] hover:text-white rounded-3xl px-2'>Itinerary</li>
                <li className='hover:bg-[#1C1C1C] hover:text-white rounded-3xl px-2'>Transfer</li>
                <li className='hover:bg-[#1C1C1C] hover:text-white rounded-3xl px-2'>Stays</li>
                <li className='hover:bg-[#1C1C1C] hover:text-white rounded-3xl px-2'>Packing guides</li>
                <li className='hover:bg-[#1C1C1C] hover:text-white rounded-3xl px-2'>Alerts</li>
            </ul>
          </div>

          <div className='flex'>
            <div className='mt-9 '>
              {parsedData && parsedData.itinerary.map((dayData, index) => {
                  const dayNumber = dayData.day.split(' ')[1];
                  return (
                    <li key={index} onClick={() => handleDayClick(dayNumber)}>
                      <Day 
                        dayNumber={dayNumber} 
                        onClick={handleDayClick} 
                        isSelected={selectedDay === dayNumber}
                      />
                    </li>
                  );
                })}
            </div>

              {parsedData && parsedData.itinerary
                  .filter(dayData => dayData.day.split(' ')[1] === selectedDay)
                  .map((dayData, index) => (
                    <DayDetail
                      key={index}
                      date={dayData.date}
                      activities={dayData.activities}
                    />
                  ))
                }         
          </div>

          <div className='flex w-[31 rem] h-[5rem] shadow-xl px-4 gap-2 rounded-[10px] ml-[15%] p-6 cursor-pointer items-center justify-center' onClick={toggleDialogBox}>
            <div>
              <AddPlan className='w-[1.688rem] h-[1.688rem]' />
            </div>
            <p className='font-dm-sans font-medium text-2xl'>Add Your Plan</p>
          </div> 
        </div>

        <div className='w-[654px] border border-gray-400 rounded-md'>
          <div className='w-full h-full'>
            <iframe
              className='w-full h-full border border-gray-100 rounded-md'
              loading="lazy"
              src={mapSrc}
              allowFullScreen
              aria-hidden="false"
              tabIndex="0"
              title="Destination Map"
            />
          </div>
            <div className='absolute top-[70px] right-[15px] p-2'>
              <ModifyDate />
            </div>
        </div>

      </div>
      <div className='mt-16'>
        <p className='font-poppins font-medium text-4xl text-center'>Packing Guides</p>
        <div className='flex justify-center gap-8 mt-9'>
        {parsedData && (
            <>
              <PackingGuides
                heading={Object.keys(parsedData.packingList)[0]}
                img1={<BeachTowel />}     // Static Image Component
                img2={<BeachBag />}       // Static Image Component
                img3={<WaterBottle />}    // Static Image Component
                name1='Towel'             // Static Text
                name2='Beach Bag'         // Static Text
                name3='Water Bottle'      // Static Text
                line1={parsedData.packingList[Object.keys(parsedData.packingList)[0]][0]}
                line2={parsedData.packingList[Object.keys(parsedData.packingList)[0]][1]}
                line3={parsedData.packingList[Object.keys(parsedData.packingList)[0]][2]}
                line4={parsedData.packingList[Object.keys(parsedData.packingList)[0]][3]}
                line5={parsedData.packingList[Object.keys(parsedData.packingList)[0]][4]}
                line6={parsedData.packingList[Object.keys(parsedData.packingList)[0]][5]}
              />
              <PackingGuides
                heading={Object.keys(parsedData.packingList)[1]}
                img1={<Cash />}           // Static Image Component
                img2={<TravelGuide />}    // Static Image Component
                img3={<FirstAid />}       // Static Image Component
                name1= 'Cash, Cards'      // Static Text
                name2= 'Travel Guide'     // Static Text
                name3= 'Snacks'           // Static Text
                line1={parsedData.packingList[Object.keys(parsedData.packingList)[1]][0]}
                line2={parsedData.packingList[Object.keys(parsedData.packingList)[1]][1]}
                line3={parsedData.packingList[Object.keys(parsedData.packingList)[1]][2]}
                line4={parsedData.packingList[Object.keys(parsedData.packingList)[1]][3]}
                line5={parsedData.packingList[Object.keys(parsedData.packingList)[1]][4]}
                line6={parsedData.packingList[Object.keys(parsedData.packingList)[1]][5]}
              />
              <PackingGuides
                heading={Object.keys(parsedData.packingList)[2]}
                img1={<Charger />}         // Static Image Component
                img2={<Camera />}          // Static Image Component
                img3={<PowerBank />}       // Static Image Component
                name1= 'Charger'           // Static Text
                name2= 'Camera'            // Static Text
                name3= 'Power Bank'        // Static Text
                line1={parsedData.packingList[Object.keys(parsedData.packingList)[2]][0]}
                line2={parsedData.packingList[Object.keys(parsedData.packingList)[2]][1]}
                line3={parsedData.packingList[Object.keys(parsedData.packingList)[2]][2]}
                line4={parsedData.packingList[Object.keys(parsedData.packingList)[2]][3]}
                line5={parsedData.packingList[Object.keys(parsedData.packingList)[2]][4]}
                line6={parsedData.packingList[Object.keys(parsedData.packingList)[2]][5]}
              />
            </>
          )}
        </div>
      </div>

      <div className='flex justify-center mt-12'>
        <button
          className='bg-[#1C1C1C] hover:bg-[#FFB543] hover:text-[#1C1C1C] text-white font-poppins font-medium text-xl px-8 py-4 rounded-md transition-all'
          onClick={handleSaveItinerary}
        >
        Save My Itinerary
        </button>
      </div>

      <div className='flex justify-between mt-16'>
        <p className='font-poppins font-medium text-4xl ml-16'>Stay</p>
        <div className='flex gap-4 mr-28'>
          <StayButton buttonText='Hotel' />
          <StayButton buttonText='Hostel' />
          <StayButton buttonText='Homestay' />
        </div>
      </div>
      <div className='p-4'>
        <StayDetailBox checkingDate='May 4, 2024'
          checkoutDate='May 6, 2024'
          stayPlaceName='Hotel Anjuna'
          stayDuration='2 nights, 3 days'
          checkingTime='02:00 pm'
          checkoutTime='11:00 am'
        />
      </div>
      <div className='flex justify-between mt-16'>
        <p className='font-poppins font-medium text-4xl ml-16'>Alerts</p>
        <div className='flex gap-4 mr-28'>
          <StayButton buttonText='Travel Advisory' />
          <StayButton buttonText='News' />
        </div>
      </div>
      <div className='flex justify-center gap-16 p-20'>
        <Alerts alertHeading='Avoid getting in the taxi if your driver is intoxicated'
          alertDetail='Goa is generally safe but it is not recommended to stray off the beaten track or unlit areas. As there is a big drug scene in Goa, if you need to get a taxi at night, keep in mind that there could be a risk that the taxi driver is high or drunk, so avoid getting in the car.' />
        <Alerts alertHeading='Is Goa safe for girls at night?'
          alertDetail='Goa is generally considered a safer destination for solo female travelers compared to some other parts of the country. However, it is important to take precautions if you are out alone at night. Stick to more populated areas and take well-lit, main roads.' />
        <Alerts alertHeading='Is it safe to rent a scooter in Goa?'
          alertDetail='Depending on where you are in Goa, its generally pretty chilled out and it is not like trying to drive a scooter in Delhi. Just make sure you donot get ripped off. The price should vary somewhere between 150 Rs - 300 Rs per day, depending on the amount of time you are hiring it for.' />
      </div>
      {isRemoveConfirmationBoxVisible && (
        <RemoveConfirmationBox
          text={itemToRemove?.activities}
          onConfirm={confirmRemoveItem}
          onCancel={() => setIsRemoveConfirmationBoxVisible(false)}
        />
      )}
      {isDialogBoxVisible && <AddPlanDialogBox />}
    </div>
      );
    }
};

export default ItineraryPlanner;
