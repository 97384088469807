const ContinueButton = ({ text, onClick }) => {
  return (
    <div
      className='w-[144px] h-[40px] bg-[#FFC34F] rounded-[8px] text-center py-0.5 font-semibold text-base text-black cursor-pointer'
      onClick={onClick}  // Add the onClick handler here
    >
      <p className='mt-2'>{text}</p>
    </div>
  );
}

export default ContinueButton;
