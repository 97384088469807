import React, { useState, useEffect, useRef } from "react";
import LandingExplore from "../../assets/LandingExplore.svg";
import LandingPlan from "../../assets/LandingPlan.svg";
import ContinueButton from "../landingPage/ContinueButton"; // Import your ContinueButton component
import NewPExploreDestination from "../landingPage/NewExploreDestination"; // Import your NewPExploreDestination component
import NewPlanMyItinerary from "../landingPage/NewPlanMyItinerary"; // Import your NewPlanMyItinerary component

const SplitHoverComponent = () => {
  const [leftWidth, setLeftWidth] = useState(50); // Initial width for left section
  const [rightWidth, setRightWidth] = useState(50); // Initial width for right section
  const [expanding, setExpanding] = useState(null); // State to track which section is expanded

  const containerRef = useRef(null); // To handle clicks outside of the container and reset the state

  const handleExpand = (section) => {
    console.log(`Expanding ${section} section`); // Debug log
    if (section === "left") {
      setLeftWidth(80);
      setRightWidth(20);
    } else if (section === "right") {
      setLeftWidth(20);
      setRightWidth(80);
    } else {
      console.error("Invalid section specified"); // Debug log
    }
    setExpanding(section);
  };

  const handleReset = () => {
    setExpanding(null);
    setLeftWidth(50);
    setRightWidth(50);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        handleReset();
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full h-full flex relative mt-2" ref={containerRef}>
      {/* Left Section (Explore Destinations) */}
      <div
        className={`h-full relative transition-all duration-700 ease-in-out`}
        style={{
          width: `${leftWidth}%`,
          backgroundImage: `url('LandingExplore')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* Conditional rendering for the Explore Destination part */}
        {expanding === "right" ? (
          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center bg-black bg-opacity-75 h-screen ">
            <p
              className="text-[#dedede33] mt-[80%] text-[48px] font-bold"
              style={{ transform: "rotate(-90deg)" }}
            >
              Explore Destinations
            </p>
            <div className="mt-[45%] ml-[25%] cursor-pointer">
              <ContinueButton text="Continue" onClick={() => handleExpand("left")} />
            </div>
          </div>
        ) : (
          <div
            className={`absolute inset-0 bg-black bg-opacity-50 text-white flex flex-col justify-center items-center ${
              expanding === "right" ? "opacity-0 transition-opacity duration-700" : ""
            }`}
          >
            <div className={`${expanding === "right" ? "hidden" : ""}`}>
              <div className="text-[67px] font-extrabold text-center">
                <p>Explore</p>
                <p>Destinations</p>
              </div>
              <div className="text-[24px] font-extrabold text-center mt-4">
                <p>We’re your one-stop solution to</p>
                <p>plan the perfect holiday.</p>
              </div>
              <div className="mt-4 cursor-pointer">
                <ContinueButton text="Continue" onClick={() => handleExpand("left")} />
              </div>
            </div>
          </div>
        )}

        {/* New Explore Destination component appears when left side is expanded */}
        {expanding === "left" && (
          <div className="absolute top-[50%] left-[1%] w-full h-full z-20">
            <NewPExploreDestination />
          </div>
        )}
      </div>

      {/* Right Section (Plan My Itinerary) */}
      <div
        className={`h-full relative transition-all duration-700 ease-in-out ${expanding === "left" ? "blur-0" : ""}`}
        style={{
          width: `${rightWidth}%`,
          backgroundImage: `url('LandingPlan')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* Conditional rendering for the Plan My Itinerary part */}
        {expanding === "left" ? (
          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center w-[100%] bg-black bg-opacity-85 h-screen ">
            <p
              className="text-[#dedede33] mt-[80%] text-[48px] font-bold "
              style={{ transform: "rotate(90deg) " }}
            >
              Plan My Itineraries
            </p>
            <div className="mt-[45%] ml-[25%] cursor-pointer ">
              <ContinueButton text="Continue" onClick={() => handleExpand("right")} />
            </div>
          </div>
        ) : (
          <div
            className={`absolute inset-0 bg-black bg-opacity-50 text-white flex flex-col justify-center items-center ${expanding === "left" ? "opacity-0 transition-opacity duration-700" : ""}`}
          >
            <p className="text-[67px] font-extrabold text-center">Plan My Trip</p>
            <div className="text-[24px] font-extrabold text-center mt-4">
              <p>We’re your one-stop solution to</p>
              <p>plan the perfect holiday.</p>
            </div>
            <div className="mt-16 cursor-pointer">
              <ContinueButton text="Continue" onClick={() => handleExpand("right")} />
            </div>
          </div>
        )}

        {/* New Plan My Itinerary component appears when right side is expanded */}
        {expanding === "right" && (
          <div className="absolute top-[30%] left-[1%] w-full h-full z-20">
            <NewPlanMyItinerary />
          </div>
        )}
      </div>
    </div>
  );
};

export default SplitHoverComponent;
