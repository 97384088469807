import React from 'react'
import { ReactComponent as DropDown } from "../../assets/dropDown.svg";

const FilterAndActivitiesOptions = ({icon , optionType}) => {
return (
    <div className='border-[1px] border-[#8F8F8F] bg-white p-4 flex justify-between rounded-[10px]'>
        <div className='flex gap-2'>
            <div className='w-6 h-6'>{icon}</div>
            <div className='font-poppins font-medium text-lg'>{optionType}</div>
        </div>
        <div className='w-6 h-6'>
        <DropDown />
        </div>
        
    </div>
)
}

export default FilterAndActivitiesOptions
