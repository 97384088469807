import axios from 'axios';

const HOSTINGER = 'Yes';   

const baseURL = HOSTINGER === 'Yes' ? "https://travelmagiq.com/api/" : "http://localhost:8800/api/";

const axiosInstance = axios.create({
  baseURL: baseURL
});

export { axiosInstance };
