import React from 'react'
import NewExploreDestination from '../../components/landingPage/NewExploreDestination'
import PreferencesTags from '../../components/landingPage/PreferencesTags'
import NewPlanMyItinerary from '../../components/landingPage/NewPlanMyItinerary'
import NewExploreDestinationMobileView from '../../components/landingPage/NewExploreDestinationMobileView'
import SplitHoverComponent from '../../components/landingPage/SplitHover'
import NewTrendingDestinationCard from '../../components/landingPage/NewTrendingDestinationCard'
import NewPopularItineraryCard from '../../components/landingPage/NewPopularItineraryCard'
import NewCityPlaceCard from '../../components/CityExplorer/NewCityPlaceCard'

const LandingPage2 = () => {
  return (
    <>
    <div>
      <SplitHoverComponent />
    </div>
    <div className='bg-black p-12'>
      <NewExploreDestination />
      <div className='mt-4'>
      <PreferencesTags />
      
      </div>
      <div className='mt-2'>
      <NewPlanMyItinerary />
      </div>
      <div className='mt-2'>
        <NewExploreDestinationMobileView />
      </div>
      <div className='mt-2'>
        <NewTrendingDestinationCard 
          placeName="Venice"
        />
      </div>
      <div className='mt-2'>
        <NewPopularItineraryCard 
          placeName='Venice'
          days='3'
          nights='2'
          rate='5000'
        />
      </div>
      <div className='mt-2'>
        <NewCityPlaceCard 
          placename="butterfly beach"
          travetime='6 - 120 mins'
        />
      </div>
    </div>
    </>
  )
}

export default LandingPage2