import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1E3A8A', // Use your Tailwind primary color
    },
    secondary: {
      main: '#9333EA', // Use your Tailwind secondary color
    },
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif', // Align with Tailwind's default font
  },
});

export default theme;
