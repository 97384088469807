import React from 'react'

const Alerts = ({placename , date , alert, alertDescription}) => {
  return (
    <div>
      <div className='w-[10.563rem] h-[12.625rem] font-plus-jakarta-sans font-semibold text-base rounded-[20px] '>
      <div className=' h-[3.536rem] bg-[#0A32AB1A] flex justify-between p-4 rounded-t-[20px]'>
            <p>{placename}</p>
            <p>{date}</p>
      </div>
      <div className='p-4 bg-[#F3F7FF] rounded-b-[20px] '>
      <p className=' mt-3'>{alert}</p>
      <p className='mt-2 font-medium'>{alertDescription}</p>
      </div>
    </div>
    </div>
  )
}

export default Alerts
