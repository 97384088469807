import React, { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";

const TravellingMultipleCity = ({ index, placeholder, onRemove }) => {
  const [count, setCount] = useState(1);

  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const handleDecrement = () => {
    setCount((prevCount) => Math.max(prevCount - 1, 1));
  };

  return (
    <div className="flex gap-x-2 mt-2">
      <input
        className="border-[1px] border-black rounded-[10px] w-[310px] h-[56px] p-3"
        placeholder={placeholder}
      />
      <div className="flex gap-x-1 mt-3">
        <div className="border-[1px] border-black rounded-[10px] w-[222px] h-[56px] flex p-3 gap-x-2 items-center">
          <PersonIcon />
          <div
            className="w-[54px] h-[30px] bg-[#FFEDD0] rounded-[10px] text-center cursor-pointer flex justify-center items-center"
            onClick={handleDecrement}
          >
            -
          </div>
          <p>{count}</p>
          <div
            className="w-[54px] h-[30px] bg-[#FFB543] rounded-[10px] text-center cursor-pointer flex justify-center items-center"
            onClick={handleIncrement}
          >
            +
          </div>
        </div>
        {index > 0 && (
          <button
            className="text-red-500 text-sm cursor-pointer"
            // onClick={() => onRemove(index)} // Remove the city
            onClick={onRemove}

          >
            Remove
          </button>
        )}
      </div>
    </div>
  );
};

export default TravellingMultipleCity;
