import React from 'react'

const BestTimeToVisit = ({startMonth, endMonth, tempSymbol,initialTemp, finalTemp, seasonType}) => {
  return (
    <div className='w-max h-[8.75rem]  bg-white p-5 font-medium font-dm-sans rounded-2xl flex flex-col gap-y- items-center cursor-default'>

        <p className=' font-semibold text-lg'>{startMonth} - {endMonth}</p>
        
        <div className='w-8 h-8 '>
          {tempSymbol}
        </div>
        <div>
          <p className='font=plus-jakarta-sans font-semibold text-xl'>{initialTemp} to {finalTemp} C</p>
        </div>
        <p className=' text-base  text-[#1F8F74]'>{seasonType}</p>
    </div>
  )
}

export default BestTimeToVisit
