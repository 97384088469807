import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DatePicker from "react-datepicker";
import EventIcon from "@mui/icons-material/Event";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PersonIcon from "@mui/icons-material/Person";
import PreferencesBox from "./PreferencesBox";
import PreferencesTags from "./PreferencesTags";
import TravellingMultipleCity from "./TravellingMultipleCity";

const NewPExploreDestination = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showPreferencesTags, setShowPreferencesTags] = useState(false);
  const [cities, setCities] = useState([1]); // Initially one city
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setCities((prev) => [...prev, prev.length + 1]); // Add a new city
    }
  };

  const handleAddCity = () => {
    setCities((prev) => [...prev, prev.length + 1]); // Add a new city
  };

  const handleRemoveCity = (index) => {
    if (cities.length > 1) {
      const updatedCities = cities.filter((_, i) => i !== index);
      setCities(updatedCities); // Update cities state
    }
  };

  const handleContinueClick = () => {
    setShowPreferencesTags(true);
  };

  return (
    <div className="flex w-full transition-all duration-300">
      {showPreferencesTags ? (
        <PreferencesTags />
      ) : (
        <div className="w-[398px] lg:w-[1193px] bg-white p-2 lg:p-4 font-Plus Jakarta Sans font-medium">
          {/* Back Button and Step Info */}
          <div className="flex gap-x-4">
            <ArrowBackIcon className="text-[#3D4F67]" />
            <p className="text-[#797676]">Step 1 of 2</p>
          </div>

          {/* Preferences Section */}
          <div className="p-4 lg:px-10 mt-2">
            <div className="flex justify-between">
              <p className="text-[#3D4F67]">Select your preferences</p>
              <div className="hidden lg:flex gap-x-2 cursor-pointer">
                <PreferencesBox text="Domestic" />
                <PreferencesBox text="International" />
                <PreferencesBox text="Both" />
              </div>
            </div>

            {/* Inputs Section */}
            <div className="flex flex-col lg:flex-row justify-between lg:items-center gap-y-6 mt-4">
              {/* Starting From */}
              <div className="flex flex-col lg:w-[48%]">
                <p className="text-[#484646] font-medium">Starting from</p>
                {cities.map((_, index) => (
                  <TravellingMultipleCity
                    key={index} // Unique key for each city to help React identify changes
                    index={index}
                    placeholder={`Destination ${index + 1}`}
                    onRemove={() => handleRemoveCity(index)} // Pass the remove function to each component
                  />
                ))}
                <div className="flex items-center gap-x-2 mt-2">
                  {isChecked ? (
                    <CheckBoxIcon
                      onClick={handleCheckboxChange}
                      className="cursor-pointer"
                    />
                  ) : (
                    <CheckBoxOutlineBlankIcon
                      onClick={handleCheckboxChange}
                      className="cursor-pointer"
                    />
                  )}
                  <p>Travelling from multiple cities?</p>
                  <button
                    className="ml-4 px-4 py-2 bg-[#FFB543] text-black rounded-lg cursor-pointer"
                    onClick={handleAddCity}
                  >
                    Add City
                  </button>
                </div>
              </div>

              {/* Duration Section */}
              <div className="flex flex-col lg:w-[48%]">
                <p className="text-[#484646] font-medium">Duration</p>
                <div className="flex mt-2 gap-x-3">
                  <div className="flex items-center w-[50%] border-[1px] border-black rounded-[10px] p-3">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Start Date"
                      showYearDropdown
                      showMonthDropdown
                      className="w-full"
                    />
                    <EventIcon />
                  </div>
                  <div className="flex items-center w-[50%] border-[1px] border-black rounded-[10px] p-3">
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="End Date"
                      showYearDropdown
                      showMonthDropdown
                      minDate={startDate}
                      className="w-full"
                    />
                    <EventIcon />
                  </div>
                </div>
                <div className="flex items-center gap-x-2 mt-2">
                  <CheckBoxOutlineBlankIcon />
                  <p>Not sure? Let us help</p>
                </div>
              </div>
            </div>
          </div>

          {/* Location Preference Section */}
          <p className="lg:hidden text-[#484646] mt-4 font-medium">Location Preference</p>
          <div className="flex lg:hidden gap-x-2 mt-2 cursor-pointer">
            <PreferencesBox text="Domestic" />
            <PreferencesBox text="International" />
            <PreferencesBox text="Both" />
          </div>

          {/* Navigation Buttons */}
          <div className="flex mt-2 lg:mt-0 gap-x-2 lg:ml-[850px] cursor-pointer">
            <div className="border-[1px] border-[#FFB543] rounded-[10px] text-center w-[179px] lg:w-[113px] h-[40px]">
              <p className="mt-2">Back</p>
            </div>
            <div
              className="h-[40px] w-[179px] lg:w-[110px] bg-[#FFB543] text-black text-center rounded-[10px]"
              onClick={handleContinueClick}
            >
              <p className="mt-2">Continue</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewPExploreDestination;
