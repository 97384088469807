import React from 'react'
import Alerts from './Alerts'

const SelectedAlert = ({text ,placename , date , alert , alertDescription}) => {
  return (
    <div className='bg-white font-plus-jakarta-sans font-[#8F8F8F] text-base p-4 '>
        <p className=''>{text}</p>
        <div className='flex gap-5 mt-1'>
            <Alerts 
                placename={placename}
                date= {date}
                alert={alert}
                alertDescription={alertDescription}
            />
            <Alerts 
                placename={placename}
                date= {date}
                alert={alert}
                alertDescription={alertDescription}
            />
             <Alerts 
                placename={placename}
                date= {date}
                alert={alert}
                alertDescription={alertDescription}
            />
        </div>
        
    
    </div>
  )
}

export default SelectedAlert
