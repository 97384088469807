import React from 'react'

const CityName = ({cityName}) => {
  return (
    <div className='p-4 rounded-[20px] py-6 bg-[#FFF5E1] w-[8.563rem] h-[5.438]'>
      <p className='text-center font-plus-jakarta-sans font-semibold text-5xl'>{cityName}</p>
    </div>
  )
}

export default CityName
