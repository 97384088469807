import React from 'react'

const StayTags = ({tag}) => {
  return (
    <div className='bg-[#F3EAD7] w-[11rem] py-1 rounded-[20px]'>
      <p className='font-poppins font-medium text-base text-center'>{tag}</p>
    </div>
  )
}

export default StayTags
