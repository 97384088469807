import React from 'react'

const Day = ({ dayNumber, onClick, isSelected }) => {
    return (
      <div
        onClick={() => onClick(dayNumber)}
        className={`w-[4.813rem] h-[2.5rem] border-[1.4px] border-[#CBD4E1] p-2 rounded-[10px] ml-3 mr-3 cursor-pointer ${
          isSelected ? 'bg-[#FFC34F]' : 'hover:bg-[#FFC34F]'
        }`}
      >
        <p className='font-poppins font-semibold text-base text-center'> Day {dayNumber}</p>
      </div>
    );
  };

export default Day