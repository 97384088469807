import React from 'react';

const Tags = ({ text, ImageComponent }) => {
  return (
    <div className="bg-[#F3EAD7] bg-opacity-90 p-2 rounded-lg  flex item-center  w-[6.693rem] h-8 gap-2">
      <div className=" ">{ImageComponent}</div>
      <div>
        <p className="text-[11.23px] font-poppins font-normal">{text}</p>
      </div>
    </div>
  );
};

export default Tags;
