import React from "react";
import { useNavigate } from "react-router-dom";


const OtherLocation = ({
  imageComponent,
  placename,
  
}) => {
    const navigate = useNavigate();
    const handlePlanYourTrip = () => {
        navigate('/city-explorer')
    }
  return (
   
    <div className="w-max h-max shadow-lg rounded-xl">
      <div className="w-max h-[16.813rem] ">{imageComponent}</div>
      <div>
        <p className="font-plus-jakarta-sans font-semibold text-2xl px-2 mt-1">
          {placename}
        </p>
      </div>
      <div className="p-4">
        <button
          className="w-full h-11  mt-2 rounded-lg  text-[#1C1C1C] text-center bg-[#FFD27A] font-poppins font-medium hover:bg-[#1C1C1C] hover:text-[#ffff]"
          onClick={handlePlanYourTrip}
        >
          Plan your trip
        </button>
      </div>
    </div>
  );
};

export default OtherLocation;
