import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PreferencesBox from './PreferencesBox';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import AcUnitOutlinedIcon from '@mui/icons-material/AcUnitOutlined';
import ThunderstormOutlinedIcon from '@mui/icons-material/ThunderstormOutlined';
import ThermostatOutlinedIcon from '@mui/icons-material/ThermostatOutlined';

const PreferencesTags = () => {
  return (
    <div className=' w-[398px]  lg:w-[1193px] lg:h-[295px] bg-white p-4 font-Plus Jakarta Sans font-medium rounded-[10px]'>
     <div className='flex gap-x-4'>
        <ArrowBackIcon className='text-[#3D4F67]'
        />
        <p className='text-[#797676]'>Step 2 of 2</p>
    </div>
    <div className=' px-3 lg:px-10 mt-2'>
    <div className='flex gap-x-[30rem]'>
    <p className='text-[#3D4F67]'>Select your preferences</p>
    <div className='hidden lg:flex gap-x-2  cursor-pointer'>
        <PreferencesBox imageComponent={<WbSunnyOutlinedIcon/>} text='Sunny'/>
        <PreferencesBox imageComponent={<AcUnitOutlinedIcon />} text='Snowy' />
        <PreferencesBox imageComponent={<ThunderstormOutlinedIcon />} text = 'Rainy' />
        <PreferencesBox imageComponent={<ThermostatOutlinedIcon />} text = 'Cold' />
    </div>
    </div>
    <div className='flex flex-col lg:flex-row gap-x-4'>
        <div className='w-[100%] lg:w-[50%] mt-2 lg:mt-0'>
            <p className='text-[#4F4E59] font-medium text-base'>Interests</p>
            <div className='flex gap-2 flex-wrap mt-2 lg:-mt-0'>
                <PreferencesBox text='Mountains'/>
                <PreferencesBox text='Beaches'/>
                <PreferencesBox text='Forests'/>
                <PreferencesBox text='Hidden Gems'/>
            </div>
            <div className='flex flex-wrap gap-2 mt-1'>
                <PreferencesBox text='Historic Sites'/>
                <PreferencesBox text='Local Towns'/>
                <PreferencesBox text='Modern Cities'/>
                <PreferencesBox text='Island'/>
            </div>
            <div className='flex flex-wrap gap-2 mt-1'>
                <PreferencesBox text='Wildlife Sanctuaries'/>
                <PreferencesBox text='Lakeside'/>
                
            </div>
        </div>
        <div className='lg:w-[50%] mt-2 lg:mt-0'>
                <p className='text-[#4F4E59] font-medium text-base'>Activities</p>
                <div className='flex flex-wrap gap-2 mt-2 lg:mt-0'>
                <PreferencesBox text='Must-see Attractions'/>
                <PreferencesBox text='Great Food'/>
                <PreferencesBox text='Heritage'/>
                <PreferencesBox text='History'/>
            </div>
            <div className='flex flex-wrap gap-2 mt-1'>
                <PreferencesBox text='Hidden Gems'/>
                <PreferencesBox text='Water Sports'/>
                <PreferencesBox text='Night Life'/>
                <PreferencesBox text='Adventure'/>
            </div>
            <div className='flex flex-wrap gap-2 mt-1'>
                <PreferencesBox text='Wildlife Santuaries'/>
                <PreferencesBox text='Architecture'/>
                <PreferencesBox text='Shopping'/>
                
            </div>
            <p className='lg:hidden mt-2 text-[#4F4E59] font-medium text-base'>Weather</p>
            <div className='lg:hidden gap-x-1 mt-2   cursor-pointer grid grid-cols-2 gap-y-1'>
            
        <PreferencesBox imageComponent={<WbSunnyOutlinedIcon/>} text='Sunny'/>
        
        
        <PreferencesBox imageComponent={<AcUnitOutlinedIcon />} text='Snowy' />
      
        <PreferencesBox imageComponent={<ThunderstormOutlinedIcon />} text = 'Rainy' />
        
        <PreferencesBox imageComponent={<ThermostatOutlinedIcon />} text = 'Cold' />
        
    </div>
            <div className='flex mt-2 gap-x-2 cursor-pointer lg:ml-[340px]'>
        <div className='border-[1px] border-[#FFB543] rounded-[10px] text-center w-[179px] lg:w-[113px] h-[40px]'>
            <p className='mt-2'>Back</p>
        </div>
        <div className='h-[40px] w-[179px] lg:w-[110px] bg-[#FFB543] text-black text-center rounded-[10px] '>
          <p className='mt-2'> Continue</p> 
        </div>
    </div>
        </div>
    </div>
    </div>
    
    </div>
  )
}

export default PreferencesTags