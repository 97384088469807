import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../utils/supabaseClient.js";
import logo from "../assets/TravelMagIQ_New_Logo.svg";
import { ReactComponent as HeaderListView } from "../assets/HeaderListView.svg";
import { ReactComponent as UserProfileIcon } from "../assets/userProfile.svg";
import { ReactComponent as SearchIcon } from "../assets/searchGrey.svg";
import GlobalSearchDialogueBox from "./GlobalSearch/GlobalSearchDialogueBox";

const Header = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchBoxRef = useRef(null);
  const [session, setSession] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const toggleSearch = () => {
    setIsSearchOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {

    // Closes the global search dialog box if clicked outside
    if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
      setIsSearchOpen(false);
    }

    // Closes the user profile dropdown if clicked outside
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Fetch session states when user is logged-in
  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);
    };
    fetchSession();

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setSession(session);
    });

    return () => {
    };
  }, []);

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Error signing out:", error.message);
    } else {
      setSession(null);
      navigate("/");   // Redirect to the landing page after sign-out
    }
  };

  const handleProfileClick = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const navigateToProfile = () => {
    navigate("/my-profile"); 
    setIsDropdownOpen(false);
  };

  return (
    <div className="flex">
      <header
        className="flex justify-between shadow-xl lg:items-center p-4 md:p-4 lg:p-6 lg:w-[100%] w-full rounded-b-3xl pl-10 md:pl-10 lg:pl-12 lg:translate-x- md:translate-x-1/16 lg:translate-x"
        style={{ backgroundColor: "#ffff" }}
      >
        <Link to="/">
          <img
            src={logo}
            alt="travelmagiQ logo"
            className="h-12 w-[125px] lg:h-[44px] translate-y-1 cursor-pointer"
          />
        </Link>
        <button className="lg:hidden">
          <HeaderListView />
        </button>

        <nav className="hidden lg:flex gap-8 translate-x-2">
          <div className="relative flex justify-start">
            <SearchIcon
              className="absolute left-4 top-1/2 transform -translate-y-1/2 text-[#7D7A91]"
              onClick={toggleSearch}
            />
            <input
              type="text"
              placeholder="Search"
              className={`search-input bg-[#F4F0EF] h-12 pl-12 rounded-3xl bg- border-[1px] border-[#3D4F67] font-plus-dm-sans font-normal text-md text-[#220f0f] transition-all duration-300 ease-out ${
                isSearchOpen ? "w-[25rem] h-16" : "w-[390px] h-12"
              }`}
              onClick={toggleSearch}
            />
          </div>

          <Link to="/explore-destination" className="text-[#3D4F67] font-plus-jakarta-sans font-semibold no-underline text-lg hover:text-[#FFC34F] flex items-center h-12 py-2">
            Destinations
          </Link>
          <Link to="/ai-travel-itinerary" className="text-[#3D4F67] font-plus-jakarta-sans font-semibold no-underline text-lg hover:text-[#FFC34F] flex items-center h-12 py-2">
            AI Travel Itinerary
          </Link>
          <Link to="/blog" className="text-[#3D4F67] font-plus-jakarta-sans font-semibold no-underline text-lg hover:text-[#FFC34F] flex items-center h-12 py-2">
            Blog
          </Link>

          <div className="hidden lg:flex items-center gap-5 -translate-x-1">
            {session ? (
              <div className="relative user-profile-dropdown" ref={dropdownRef}>

                <UserProfileIcon onClick={handleProfileClick} className="cursor-pointer" />
                
                {isDropdownOpen && (
                  <div className="absolute right-0 bg-white shadow-lg rounded-lg mt-1 cursor-pointer">
                    <button
                      onClick={navigateToProfile}
                      className="block p-4 text-base text-black hover:bg-gray-100 w-[7rem] text-center"
                    >
                      My Profile
                    </button>
                    <button
                      onClick={handleSignOut}
                      className="block p-4 text-base text-black hover:bg-gray-100 w-[7rem] text-center"
                    >
                      Sign Out
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <>
                <Link to="/log-in" className="text-[#3D4F67] font-plus-jakarta-sans font-semibold no-underline text-lg hover:text-[#FFC34F] flex items-center">
                  Log In
                </Link>
                <Link to="/sign-up">
                  <button className="bg-3D4F67 text-[#1C1C1C] font-plus-jakarta-sans font-semibold py-2 px-4 rounded-3xl hover:bg-[#1C1C1C] hover:text-[#ffff] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                    Sign Up
                  </button>
                </Link>
              </>
            )}
          </div>
        </nav>
      </header>

      {isSearchOpen && (
        <div
          className="fixed inset-0 z-50 flex justify-center items-start"
          onClick={toggleSearch} // Close when clicking outside the dialogue box
        >
          <div
            ref={searchBoxRef}
            className="relative z-50 bg-white"
            onClick={(e) => e.stopPropagation()} // Prevent click inside the box from closing it
          >
            <GlobalSearchDialogueBox toggleSearch={toggleSearch} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
